import httpService from './../config/http.service';
import { API_MAIN, APIs, VERSION } from '../utils/api';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getCategoryList = async (params: any) => {
  const res = await httpService(authUrl).get(APIs.CATEGORY, { params });
  return res?.data;
};

export const createCategory = async (payload: any) => {
  const res = await httpService(authUrl).post(APIs.CATEGORY, payload);
  return res?.data;
};

export const updateCategory = async (categoryId: string, payload: any) => {
  const res = await httpService(authUrl).put(APIs.CATEGORY + '/' + categoryId, payload);
  return res?.data;
};

export const deleteCategory = async (ids: string[]) => {
  const res = await httpService(authUrl).delete(APIs.CATEGORY, {
    data: {
      ids,
    },
  });
  return res?.data;
};
export const deleteStyleInCategory = async(id: string, payload: any) => {
  const res = await httpService(authUrl).put(APIs.CATEGORY + `/${id}/remove-styles`, payload)
  return res?.data;
}
export const updatePriorityCategory = async (payload: any) => {
  const res = await httpService(authUrl).put(APIs.CATEGORY + '/priority', payload);
  return res?.data;
};

export const addStylesToCategory = async (categoryId: string, styleIds: string[]) => {
  const res = await httpService(authUrl).put(APIs.CATEGORY + '/' + categoryId + '/styles', {
    styleIds,
  });
  return res?.data;
};
