import { Modal } from 'antd';
import { styled } from 'styled-components';

export const ModalCreate = styled(Modal)`
  .ant-select-selection-item-content {
    color: var(--scale-gray-scale-high-emp, #131317);
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .ant-input {
    color: #131317 !important;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;
    box-shadow: unset;
    padding: 12px 16px;
    min-height: 46px !important;
    &:hover {
      border-color: #F37F13 !important;
    }
    &:focus-within {
      border-color: #F37F13 !important;
    }
  }
  .ant-select-single.ant-select-lg {
    height: fit-content;
  }
  .ant-form-item-label > label {
    color: #8C8C92;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  
  .ant-select-selector {
    min-height: 48px !important;
    box-shadow: unset !important;
    border-radius: 4px !important;
    //padding: 12px 16px !important;
    &:hover {
      border-color: #F37F13 !important;
    }
    &:focus-within {
      border-color: #F37F13 !important;
    }
  }
  .ant-select-focused {
    border-color: #068e0d !important;
  }
  .ant-select-selection-item {
    //margin: 0 4px !important;
    min-height: unset;
    height: fit-content !important;
    //line-height: 22px !important;
    color: #000;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    //flex-shrink: 0;
  }
  .ant-select-arrow {
    display: flex !important;
  }
  .ant-modal-content {
    padding: 30px 40px;
  }
  .ant-form-item-label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131317;
  }
  .group-btn {
    display: flex;
    justify-content: end;
    gap: 12px;
    margin-top: 22px;
    .cancel-btn {
      width: 140px;
      height: 46px;
      background: #f7f7f7;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #131317;
      border: none;
    }
    .create-btn {
      width: 140px;
      height: 46px;
      background: #f37f13;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;

export const FrameOptionItem = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #F3F3F3;
`;

export const Space = styled.div`
  display: flex;
  height: 15vh;
`;

export const FrameTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const TxtTitle = styled.div`
  display: flex;
  color: var(--scale-gray-scale-high-emp, #131317);
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

export const FrameIcClose = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
