import { useLocation, useNavigate } from 'react-router-dom';
import { FrameLogOut, HeaderWrapper } from './style';
import AvatarDefault from '../../assets/images/avatar-default.svg';
import IconDownHeader from '../../assets/images/icon-down-header.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect } from 'react';
import tokenAuthService from '../../utils/tokenAuthService';
import { setUserInfo } from '../../redux/slices/authSlice';
import { Dropdown, MenuProps } from 'antd';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = tokenAuthService.getLocalAuthUser() || {};
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  useEffect(() => {
    if (!authData?.token) {
      navigate('/login');
    } else if (pathname === '/') {
      navigate('/projects');
    }
  }, [pathname, userInfo?.role]);

  const logOut = () => {
    dispatch(setUserInfo({}));
    tokenAuthService.removeAuthUser();
    navigate('/login');
  };

  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: <FrameLogOut onClick={logOut}>Log Out</FrameLogOut>,
    },
  ];

  return (
    <HeaderWrapper>
      <div className="left">
        {pathname === '/account'
          ? 'Account'
          : pathname === '/style'
          ? 'AI Style'
          : pathname === '/projects'
          ? 'Project'
          : pathname === '/'
          ? 'Apero Dashboard'
          : 'Project Detail'}
        {''}
      </div>
      <Dropdown menu={{ items }} placement="bottom" arrow>
        <div className="right">
          <div className="avatar">
            <img src={userInfo?.avatar || AvatarDefault} alt="" />
          </div>
          <div className="name">{`${userInfo?.username}`}</div>
          <img src={IconDownHeader} alt="" />
        </div>
      </Dropdown>
    </HeaderWrapper>
  );
};

export default Header;
