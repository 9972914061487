import { Drawer, Pagination } from 'antd';
import { styled } from 'styled-components';

export const ListStyleWrapper = styled.div`
  background-color: #f7f7f7;
  flex: 1;
  padding: 24px;
  .content {
    position: relative;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131317;
      margin-bottom: 10px;
    }
    .list-style {
      border-top: 1px solid #eaeaea;
      padding: 15px 0px;
      .box-search {
        display: flex;
        justify-content: space-between;
        .search-and-filter {
          display: flex;
          gap: 15px;
          .input-search {
            padding: 14px;
            width: 400px;
            border-radius: 4px;
            border: 1px solid #eaeaea;
            input {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              margin-left: 8px;
              &::placeholder {
                color: #8c8c92;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
          .filter {
            cursor: pointer;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;
            border: 1px solid #eaeaea;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #8c8c92;
          }
        }
        .footer {
          margin-top: 15px;
        }
        .add-ai {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f37f13;
          border-radius: 8px;
          padding: 13px 24px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: white;
          gap: 18px;
          &:hover {
            opacity: 0.8;
          }
          .plus {
            width: 20px;
            height: 20px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #f37f13;
            border-radius: 50%;
            font-size: 18px;
            flex-shrink: 0;
          }
        }
      }
      .item-project {
        background: #d1d1d1;
        border-radius: 5px;
        margin-bottom: 8px;
        padding: 0px 8px;
      }
    }
  }
`;
export const DrawerWrapper = styled(Drawer)`
  .title-filter {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #131317;
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .ant-drawer-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 32px;
  }
  .style {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #131317;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .ant-select-selector {
    height: 44px !important;
  }
  .ant-select-selection-item {
    height: 44px !important;
    display: flex;
    align-items: center;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .reset {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #8c8c92;
      cursor: pointer;
    }
  }
  .btn-cancel {
    width: 119px;
    height: 54px;
    background: #f7f7f7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #131317;
    border: none;
    margin-right: 12px;
    &:hover {
      color: #131317;
    }
  }
  .btn-apply {
    width: 119px;
    height: 54px;
    background: #f37f13;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    border: none;
    &:hover {
      color: #ffffff;
    }
  }
`;

export const FrameTools = styled.div`
  display: flex;
  gap: 12px;
`;
