import { Button, Form, Input, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { FrameIcClose, FrameOptionItem, FrameTitle, ModalCreate, Space, TxtTitle } from './style';
import { ROLES, ROLE_OPTION } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import IcClose from '../../assets/icons/ic_close.svg';
const { Option } = Select;

interface IProps {
  open: boolean;
  closeModal: () => void;
  addNewAccount: (payload: any) => void;
  updateAccount: (payload: any) => void;
  accountEdit: any;
  optionProject: any;
}

const ModalAddMember = (props: IProps) => {
  const {
    open,
    closeModal,
    addNewAccount,
    updateAccount,
    accountEdit,
    optionProject
  } = props;
  const [role, setRole] = useState(ROLES.USER);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);



  const OptionProject = useMemo(() => {
    if (userInfo?.role === ROLES.ADMIN) {

    }
    // if (userInfo?.role === ROLES.ADMIN) return userInfo?.projects.map((project: any) => {
    //   return {label: project?.name, value: project?.id}
    // });
    return [];
  }, [userInfo])

  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accountEdit) {
      form?.setFieldsValue({
        email: accountEdit?.email,
        role: accountEdit?.role,
        project: accountEdit?.projects
      })
    }
  }, [accountEdit])

  const onFinish = async (values: any) => {
    const email = values?.email || accountEdit?.email;
    let payload: any = {
      email: values?.email,
      role: values?.role,
      username: email.split('@')[0],
      isActive: true,
      projects: []
    }

    if (values?.role === ROLES.USER && values?.project.length) {
      const projects: any = values?.project.map((item: any) => {
        let index = optionProject.findIndex((project: any) => project?.value === item);
        if (index !== -1) return optionProject[index].value;
        return;
      })
      payload = {...payload, projects};
    }
    if (accountEdit) {
      payload = {...payload, id: accountEdit?._id.toString()};
      delete payload.email;
      updateAccount(payload);
    } else {
      addNewAccount(payload);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
    setLoading(false);
  };

  const handleRole = (value: any) => {
    setRole(value);
  };

  const handleCreateStyle = () => {
    form.submit();
  };

  const validateEmail = (_: any, value: string) => {
    if (!value) return Promise.reject('Email cannot be empty!');
    if (value.indexOf('@') === -1) return Promise.reject('Email must have @');
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!emailRegex.test(value)) return Promise.reject('The input is not valid email!');
    return Promise.resolve();
  };

  const renderOptionItem = (option: any, index: number) => {
    return(
      <Option value={option?.value} label={option?.label}>
        <FrameOptionItem>{option?.label}</FrameOptionItem>
      </Option>
    )
  }

  return (
    <>
      <ModalCreate
        width={641}
        footer={null}
        centered
        open={open}
        onCancel={handleCancel}
        closeIcon={null}

      >
        {contextHolder}
        <Form
          requiredMark={false}
          layout="vertical"
          colon={false}
          form={form}
          name="createStyle"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          initialValues={{
            role: ROLES.USER,
            project: []
          }}
          autoComplete="off"
        >
          <FrameTitle>
            <TxtTitle>{accountEdit ? 'Update account' : 'Add account'}</TxtTitle>
            <FrameIcClose src={IcClose} onClick={handleCancel}/>
          </FrameTitle>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                validator: validateEmail
              },
            ]}>
            <Input maxLength={150} name="name" size="large" className="name-input" disabled={!!accountEdit} placeholder={'Enter email'}/>
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: 'Please select role!',
                type: 'string',
              },
            ]}>
            <Select options={ROLE_OPTION} size="large" onChange={handleRole} placeholder={'Select role'}/>
          </Form.Item>
          {role === ROLES.USER && <Form.Item
            label="Project"
            name="project"
          >
            <Select
              mode="multiple"
              allowClear
              size="large"
              placeholder={'Select project'}
              filterOption={(inputValue: any, option: any) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            >
              {optionProject.map((option: any, index: number) => {
                return renderOptionItem(option, index);
              })}
            </Select>
          </Form.Item>}
          <Space/>
          <div className="group-btn">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="create-btn" onClick={handleCreateStyle} loading={loading}>
              {accountEdit ? 'Update account' : 'Add account'}
            </Button>
          </div>
        </Form>
      </ModalCreate>
    </>
  );
};

export default ModalAddMember;
