import { styled } from 'styled-components';

export const ListProjectWrapper = styled.div`
  background-color: #f7f7f7;
  flex: 1;
  padding: 24px;
  .content {
    position: relative;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131317;
      margin-bottom: 10px;
    }
    .list-project {
      border-top: 1px solid #eaeaea;
      padding: 15px 0px;
    }
    .list-project-table {
      width: 100%;
      .project-name {
        color: #1395f3;
        cursor: pointer;
        text-decoration: underline;
      }
      .number-style {
        text-align: center;
      }
      td.ant-table-cell {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #131317;
      }
    }
  }
`;
