import { Empty } from 'antd';
import { TableContentWrapper } from './style';

interface IPropsTableContent {
  columns: any;
  dataSource: any;
  height: string;
  loading: boolean;
  className?: string;
}

const TableContent = (props: IPropsTableContent) => {
  const { columns, dataSource, height, className, loading } = props;
  return (
    <TableContentWrapper
      loading={loading}
      columns={columns}
      bordered={false}
      dataSource={dataSource}
      rowKey={'_id'}
      scroll={{ y: height }}
      pagination={false}
      className={className}
      locale={{ emptyText: <Empty description={"No Result"} /> }} 
    />
  );
};

export default TableContent;
