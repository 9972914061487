import React, { ReactNode } from 'react';
import styled from 'styled-components';

type ButtonType = 'filled' | 'outlined';

interface PropsType {
  content: string;
  onClick?: (e?: any) => void;
  prefix?: ReactNode;
  type?: ButtonType;
}

const Wrapper = styled.div<{ type: ButtonType }>`
  cursor: pointer;
  border-radius: 8px;
  background: ${(props) => (props.type === 'filled' ? '#f37f13' : 'transparent')};
  border: 1px solid #f37f13;
  padding: 0px 24px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  p {
    //styleName: Body/ Semi;
    color: ${(props) => (props.type === 'filled' ? '#fff' : '#f37f13')};
    /* Body/ Semi */
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    margin-bottom: 0px;
  }
`;

export default function CustomButton({ content, onClick, prefix, type = 'filled' }: PropsType) {
  return (
    <Wrapper onClick={onClick} type={type}>
      {prefix && prefix}
      <p>{content}</p>
    </Wrapper>
  );
}
