export const VERSION = {
  V3: '/v3',
};

export const API_MAIN = {
  PROJECTS: 'projects',
  STYLES: 'styles',
};

export const APIs = {
  GET_PROFILE: '/accounts/me',
  ACCOUNTS: '/accounts',
  LOGIN: '/auth/login',
  REFRESH_TOKEN: '/auth/refresh-token',
  LIST_PROJECT: `/${API_MAIN.STYLES}/${API_MAIN.PROJECTS}`,
  THUMBNAIL_TYPE: `/${API_MAIN.STYLES}/${API_MAIN.PROJECTS}/thumbnail-type`,
  LIST_SEGMENT: `/segments`,
  LIST_SEGMENT_ALL: `/segments/all`,
  DETAIL_SEGMENT: (id: string) =>  `/segments/${id}`,
  CATEGORY: '/category',
};
