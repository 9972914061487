import { Modal } from 'antd';
import { styled } from 'styled-components';

export const ModalCreate = styled(Modal)`
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f37f13;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #f37f13;
    background-color: #f37f13;
  }
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: #f37f13 !important;
    background-color: #f37f13 !important;
  }

  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-arrow {
    display: flex !important;
  }
  .ant-modal-content {
    padding: 30px 40px;
    max-height: 90vh;
    overflow-y: auto;
  }
  .ant-form-item-label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131317;
  }
  .group-btn {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 22px;
    .cancel-btn {
      width: 140px;
      height: 46px;
      background: #f7f7f7;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #131317;
      border: none;
    }
    .create-btn {
      width: 140px;
      height: 46px;
      background: #f37f13;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;
