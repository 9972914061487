import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserInfo {
  id?: string;
  username?: string;
  avatar?: string;
  role?: string;
  projects?: any;
}

interface IProps {
  userInfo: IUserInfo
}

const initialState: IProps = {
  userInfo: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload
    },
  },
});

export const {
  setUserInfo,
} = authSlice.actions;
export default authSlice.reducer;
