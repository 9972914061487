import { APIs } from '../utils/api';
import httpService from './../config/http.service';

const authUrl = process.env.REACT_APP_BASE_URL;
const authService = process.env.REACT_APP_AUTHEN_BASE_URL;

const authServices = {
    getProfile() {
        return httpService(authUrl).get(APIs.GET_PROFILE, {});
    },
    getListAccounts(payload: any) {
        const query = '?' + new URLSearchParams(payload).toString();
        return httpService(authUrl).get(APIs.ACCOUNTS + query);
    },
    deleteAccount(id: string) {
        return httpService(authUrl).delete(`${APIs.ACCOUNTS}/${id}`);
    },
    addNewAccount(payload: any) {
        return httpService(authUrl).post(`${APIs.ACCOUNTS}`, payload);
    },
    updateAccount(payload: any) {
        const id = payload?.id;
        delete payload.id;
        return httpService(authUrl).put(`${APIs.ACCOUNTS}/${id}`, payload);
    },
    login(username: string, password: string) {
        return httpService(authUrl).post(APIs.LOGIN, {
            username,
            password
        });
    }
};

export default authServices;