import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { arrayMoveImmutable } from 'array-move';
import { Button } from 'antd';
import { ArrangeStyleModalWrapper } from './style';
import SortableList from './sortList';
import { editPriorityStyle } from '../../services/detailProjectService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';
import { arrangeCategoryStyles } from '../../services/listStyleService';

const ArrangeStyleModal = (props: any) => {
  const { open, setOpen, listStyle, params, setParams, categoryId, refresh } = props;
  const queryClient = useQueryClient();
  const [items, setItems] = useState<any>([]);
  const [oldIndex, setOldIndex] = useState(-1);
  const [newIndex, setNewIndex] = useState(-1);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const items = listStyle?.map((style: any) => ({
      nameApp: style?.name,
      thumbnailApp: style?.key,
      _id: style?._id,
    }));
    setItems(items);
  }, [listStyle]);

  const mutationEditPriority = useMutation(
    () =>
      arrangeCategoryStyles(
        categoryId,
        arrayMoveImmutable(items, oldIndex, newIndex).map((item: any) => item._id)
      ),
    {
      onSuccess: (data) => {
        onCancel();
        setParams({ ...params, page: 1, name: '' });
        // setNameSearch('');
        queryClient.invalidateQueries({
          queryKey: ['get-detail-category'],
        });
        ToastSuccess(toastMessage.success);
        refresh();
      },
      onError: () => {
        ToastError(toastMessage.error);
      },
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
    if (oldIndex !== newIndex) {
      setOldIndex(oldIndex);
      setNewIndex(newIndex);
      setReady(true);
    }
  };
  useEffect(() => {
    if (ready && oldIndex !== -1 && newIndex !== -1) {
      const id = items[newIndex]._id;
      const body = {
        positionIndex: newIndex,
      };
      if (id) {
        // mutationEditPriority.mutate();
        setReady(false);
        setOldIndex(-1);
        setNewIndex(-1);
        // Reset ready state after the mutation is triggered
      }
    }
  }, [ready, oldIndex, newIndex]);

  const onSave = () => {
    mutationEditPriority.mutate();
  };

  const onCancel = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <ArrangeStyleModalWrapper
      open={open}
      width={476}
      closable={false}
      footer={null}
      centered
      onCancel={onCancel}>
      <div className="title">Edit Priority</div>
      <div className="title-sort">
        <span>Style name on app</span>
        <span>Thumbnail on app</span>
      </div>
      <SortableList items={items} onSortEnd={onSortEnd} />
      <div className="footer">
        <Button className="btn-cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="btn-add" onClick={onSave} loading={mutationEditPriority.isLoading}>
          Save
        </Button>
      </div>
    </ArrangeStyleModalWrapper>
  );
};

export default ArrangeStyleModal;
