import axiosInstance from './axiosInstance';

import axios from 'axios';

export const getPresignUrl = (params: any) => {
  return axiosInstance.get('/styles/presign', {
    params,
  });
};

export const uploadImageApi = (url: any, payload: any) => {
  return axios.put(url, payload);
};
