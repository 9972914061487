import { Avatar, Input, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IconDeleteStyle from '../../assets/images/icon-delete-style.svg';
import IconEditStyle from '../../assets/images/icon-edit-style.svg';
import IconSearch from '../../assets/images/icon-search.svg';
import ArrangeStyleModal from '../../components/ArrangeStyleModal';
import CustomButton from '../../components/Button/CustomButton';
import PlusIcon from '../../components/Icons/PlusIcon';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import ModalCustomStyleProject from '../../components/ModalCustomStyleProject';
import Pagination from '../../components/Pagination';
import TableContent from '../../components/TableContent';
import { ToastError, ToastSuccess } from '../../components/toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';
import { RootState } from '../../redux/store';
import { baseModels, FieldConfigStyle, KEY_CONFIG, StyleType, TABS } from '../../constants';
import { getDetailCategory } from '../../services/detailCategoryService';
import { deleteStyle, getAllStyle, getListStyleOfCategory } from '../../services/listStyleService';
import IcBackColor from './../../assets/icons/ic_back_color.svg';
import AddStyleModal from './AddStyleModal';
import { BackButton, DetailCategoryWrapper, HeaderTitle } from './style';
import { checkIsImage } from '../../utils/helper';
import { deleteStyleInCategory } from '../../services/categoryService';
const DetailCategory = () => {
  const segment = useSelector((state: RootState) => state.style.styleInfo.segment);
  const listImageSize = useSelector((state: RootState) => state.style.styleInfo.listImageSize);
  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 70,
    },
    {
      title: 'STYLE NAME ON APP',
      dataIndex: 'name',
      key: 'name',
      width: 170,
    },
    /* {
      title: 'STYLE NAME ON APP',
      dataIndex: 'nameApp',
      key: 'nameApp',
      width: 170,
    }, */
    {
      title: 'POSITIVE PROMPT',
      dataIndex: 'config',
      key: 'positivePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.positivePrompt || ''}</div>;
      },
    },
    {
      title: 'NEGATIVE PROMPT',
      dataIndex: 'config',
      key: 'negativePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.negativePrompt || ''}</div>;
      },
    },
    {
      title: 'CONFIG',
      dataIndex: 'config',
      key: 'config',
      className: 'config-column',
      width: 320,
      render: (value: any, record: any) => {
        return (
          <div>
            <ul>
              {KEY_CONFIG[record.styleType]
                ?.filter((key: string) => !(record.styleType === 'avatar' && key === 'imageSize'))
                ?.map((key: string) => {
                  if (key === 'mode') {
                    if (record.styleType === StyleType.AVATAR) return <></>;
                  }
                  if (key === 'baseModel') {
                    return `${FieldConfigStyle[key]} :  ${value[key] || baseModels[0]?.label}`;
                  }
                  return (
                    <>
                      <li>{`${FieldConfigStyle[key]} : ${
                        value[key] !== undefined ? value[key] : ''
                      }`}</li>
                    </>
                  );
                })}
            </ul>
          </div>
        );
      },
    },
    {
      title: 'PROPERTIES',
      dataIndex: 'metadata',
      key: 'metadata',
      width: '240px',
      render: (value: any) => {
        return value?.map((e: any, index: number) => (
          <Tag key={index}>{`${e.key}: ${e.value}`}</Tag>
        ));
      },
    },
    {
      title: 'THUMBNAIL ON APP',
      dataIndex: 'key',
      key: 'key',
      className: 'thumbnail-column',
      width: '240px',
      render: (value: string) => {
        const isImage = checkIsImage(value);
        if (isImage) return <Avatar shape="square" size={204} src={value}></Avatar>;
        return (
          <video width="204px" height="204px" key={value} controls>
            <source src={value} />
          </video>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      className: 'action-column',
      fixed: 'right',
      render: (id: string, record: any) => (
        <div className="list-action">
          <img src={IconEditStyle} alt="" onClick={() => handleOpenEdit(record)} />
          <img src={IconDeleteStyle} alt="" onClick={() => handleOpenDelete(id)} />
        </div>
      ),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { categoryId, projectId } = useParams();
  // const projectName = sessionStorage.getItem('projectName');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [styleEdit, setStyleEdit] = useState<any>();
  const [styleIds, setStyleIds] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [styles, setStyles] = useState<any>({
    limit: 15,
    totalItems: 9,
    items: [],
  });
  const [allStyles, setAllStyles] = useState<any>([]);
  const [params, setParams] = useState<any>({
    limit: 15,
    page: 1,
    segmentId: '',
    isStyleOfCategory: true,
    // project: projectName,
  });
  const [nameSearch, setNameSearch] = useState('');
  const [openEditPriority, setOpenEditPriority] = useState(false);
  let [debounce, setDebounce] = useState<any>();
  const [openAddStyle, setOpenAddStyle] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState<any>();
  const [openCustom, setOpenCustom] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idStyleDelete, setIdStyleDelete] = useState('');

  // window.addEventListener('popstate', () => {
  //   navigate(-1);
  // });

  useEffect(() => {
    // 1. Set initial params based on searchParams
    const param: any = {
      page: 1,
      limit: 15,
      search: '',
    };
    const page = searchParams.get('page');
    const limit = searchParams.get('limit');
    const search = searchParams.get('search');
    if (page) {
      param.page = Number(page);
    }
    if (limit) {
      param.limit = Number(limit);
    }
    if (search) {
      param.search = search;
      setNameSearch(search);
    }

    // 2. Update params based on the previous params and search
    const updatedParams = { ...param };
    if (!search) {
      updatedParams.page = 1;
      updatedParams.limit = 15;
      setNameSearch('');
    }

    // 3. Set the final params state and searchParams
    setParams(updatedParams);
    setSearchParams(updatedParams);

    // 4. Optional: Perform other logic if needed

    // Note: The dependency array [] ensures this useEffect only runs once on component mount.
  }, []);
  useEffect(() => {
    if (categoryId) {
      mutationGetCategoryDetail.mutate(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (params?.isStyleOfCategory && styleIds.length !== 0) {
      mutationGetListStyle.mutate();
    }
  }, [JSON.stringify(params), JSON.stringify(styleIds)]);

  useEffect(() => {
    if (params?.isStyleOfCategory && styleIds.length !== 0) {
      mutationGetAllStyle.mutate();
    }
  }, [JSON.stringify(styleIds), params.segmentId]);

  const mutationGetListStyle = useMutation(
    () => {
      return getListStyleOfCategory(params);
    },
    {
      onSuccess: (data) => {
        let order: any = {};
        styleIds.forEach((item: any, i: number) => {
          order[item] = i;
        });
        const styleMaps = data?.items?.sort((a: any, b: any) => {
          return order[a._id] - order[b._id];
        });

        setStyles({
          ...styles,
          ...data,
          items: styleMaps,
        });

        setIsLoading(false);
      },
    }
  );

  const mutationGetAllStyle = useMutation(
    () =>
      getAllStyle({
        segmentId: params.segmentId,
        isStyleOfCategory: params.isStyleOfCategory,
        styleIds: params?.styleIds || styleIds || [],
      }),
    {
      onSuccess: (data) => {
        let order: any = {};
        styleIds.forEach((item: any, i: number) => {
          order[item] = i;
        });
        const styleMaps = data?.sort((a: any, b: any) => {
          return order[a._id] - order[b._id];
        });
        setAllStyles([...styleMaps]);
        setIsLoading(false);
      },
    }
  );
  const muatationDeleteStyleInCategory = useMutation(
    ({ categoryId, payload }: any) => deleteStyleInCategory(categoryId, payload),
    {
      onSuccess: (data) => {
        handleCancelDelete();
        queryClient.invalidateQueries({
          queryKey: ['get-detail-category'],
        });
        // mutationGetAllStyle.mutate();
        // mutationGetListStyle.mutate();
        if (categoryId) {
          mutationGetCategoryDetail.mutate(categoryId);
        }
        // mutationGetAllStyle.mutate();
        // mutationGetListStyle.mutate();
        // setParams({ ...params, limit: styles.limit, page: styles.page });
        // if (styles.totalItems - 1 === (params?.page - 1) * params?.limit && params?.page > 1) {
        //   setParams({ ...params, limit: styles.limit, page: styles.page - 1 });
        // } else {
        //
        // }
        // setParams({ ...params, limit: styles.limit, page: styles.page - 1 });
        ToastSuccess('Delete successfully');
      },
      onError: () => {
        ToastError(toastMessage.error);
      },
    }
  );
  const mutationGetCategoryDetail = useMutation(
    (categoryId: string) => getDetailCategory(categoryId),
    {
      onSuccess: (data) => {
        setCategoryDetail(data);
        setCategoryName(data?.name);
        let newParams = {
          ...params,
          segmentId: data?.segment,
          type: data?.type,
        };

        if (data?.styles?.length) {
          setStyleIds(() => data?.styles);
          newParams = {
            ...newParams,
            isStyleOfCategory: true,
            styleIds: data?.styles,
          };
        } else {
          setStyleIds(() => []);
          newParams = {
            ...newParams,
            isStyleOfCategory: true,
            styleIds: data?.styles,
          };
        }
        setParams({ ...newParams });
        // mutationGetListStyle.mutate({ ...newParams });
        // mutationGetAllStyle.mutate({ ...newParams });
      },
      onError: () => {
        //  ToastError(toastMessage.error);
      },
    }
  );

  const handleOpenEditPriority = () => {
    setOpenEditPriority(true);
  };
  const handleOpenEdit = (style: any) => {
    setStyleEdit(style);
    setOpenCustom(true);
  };
  const handleOpenDelete = (id: string) => {
    setOpenDelete(true);
    setIdStyleDelete(id);
  };
  const handleCancelDelete = () => {
    setOpenDelete(false);
    setIdStyleDelete('');
  };
  const handleDelete = () => {
    setOpenDelete(false);
    const payload = {
      styleIds: [idStyleDelete],
    };
    console.log('pageeee', params?.page, params?.limit);

    muatationDeleteStyleInCategory.mutate({ categoryId, payload });

    const paramIds = params?.styleIds?.filter((style: any) => style !== idStyleDelete);
    if (styles?.totalItems - 1 === (params?.page - 1) * params?.limit && params?.page > 1) {
      setParams({ ...params, limit: styles.limit, page: styles.page - 1, styleIds: paramIds });
    } else {
      setParams({ ...params, limit: styles.limit, page: styles.page, styleIds: paramIds });
    }

    // if ((styles.totalItems - 1) % styles.limit === 0)
  };

  const reloadAllStyle = () => {
    mutationGetListStyle.mutate();
    mutationGetAllStyle.mutate();
  };

  const handleChangeSearchName = (e: any) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        setParams({ ...params, search: e.target.value, page: 1 });
      }, 500)
    );
    setNameSearch(e.target.value);
  };

  const refreshData = (newStyleIds: string[]) => {
    setStyleIds(newStyleIds);
    setParams({ ...params, styleIds: newStyleIds });
    if (categoryId) {
      mutationGetCategoryDetail.mutate(categoryId);
    }
  };

  return (
    <DetailCategoryWrapper>
      <div className="content">
        <HeaderTitle>
          <div className="title">{categoryName}</div>
          <Space />
          <BackButton
            onClick={() =>
              navigate(`/projects/${projectId}`, {
                state: {
                  segment: params.segmentId,
                  tab: TABS.TAB2,
                  type: params.type,
                },
              })
            }>
            <img src={IcBackColor} alt="back-icon" />
            Back to project
          </BackButton>
        </HeaderTitle>
        <div className="list-style">
          <div className="box-search">
            <Input
              allowClear
              value={nameSearch}
              prefix={<img src={IconSearch} alt="" />}
              className="input-search"
              placeholder="Search style name on app"
              onChange={handleChangeSearchName}
            />
            <div className="group-btn">
              <CustomButton
                content="Add Style"
                prefix={<PlusIcon />}
                onClick={() => {
                  setOpenAddStyle(true);
                }}
              />
              <CustomButton
                content=" Edit priority"
                type="outlined"
                onClick={handleOpenEditPriority}
              />
              {/* <div className="add-ai" onClick={handleCustomStyle}>
                <div className="plus">+</div>
                <div>Custom AI style to Project</div>
              </div>
              <div className="add-ai" onClick={handleOpenAddStyle}>
                <div className="plus">+</div>
                <div>Add AI style default to Project</div>
              </div> */}
            </div>
          </div>
          <TableContent
            loading={isLoading}
            columns={columns}
            dataSource={styles?.items}
            height="55vh"
          />
        </div>
        <Pagination
          limit={styles?.limit || 0}
          total={styles?.totalItems || 0}
          params={params}
          setParams={setParams}
        />
      </div>
      {openCustom && (
        <ModalCustomStyleProject
          open={openCustom}
          setOpen={setOpenCustom}
          params={params}
          setParams={setParams}
          setNameSearch={setNameSearch}
          styleEdit={styleEdit}
          setStyleEdit={setStyleEdit}
          segmentSelect={segment}
          listImageSize={listImageSize}
          reloadAllStyle={reloadAllStyle}
        />
      )}
      {openEditPriority && (
        <ArrangeStyleModal
          open={openEditPriority}
          setOpen={setOpenEditPriority}
          listStyle={allStyles}
          categoryId={categoryId}
          params={params}
          setParams={setParams}
          refresh={() => {
            mutationGetCategoryDetail.mutate(`${categoryId}`);
          }}
        />
      )}

      <AddStyleModal
        open={openAddStyle}
        onClose={() => {
          setOpenAddStyle(false);
        }}
        category={categoryDetail}
        refreshData={refreshData}
      />
      {openDelete && (
        <ModalConfirmDelete
          open={openDelete}
          handleCancelDelete={handleCancelDelete}
          handleDelete={handleDelete}
          title={'Are you sure you want to move this style from the category'}
          tittleDelete={'Remove'}
        />
      )}
    </DetailCategoryWrapper>
  );
};

export default DetailCategory;
