import { Button, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import { UploadImageWrapper } from './style';
import { ToastError } from '../toast-message/ToastMessage';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/png'  || file.type === 'image/jpeg';
  if (!isJpgOrPng) {
    ToastError('You can only upload JPG/PNG file!');
  }
  const isLt200KB = file.size / 1024 <= 200;
  if (!isLt200KB) {
    ToastError('File size over 200kb!');
  }
  return isJpgOrPng && isLt200KB;
};  

const UploadImage = (props: any) => {
  const { setFile, setImageUrl, form } = props;
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    let newFile: any;
    if (info.file.originFileObj) {
      const lastFourChars = info.file.originFileObj.name.slice(-4)
      newFile = new File([info.file.originFileObj], Math.floor(Date.now() / 1000).toString() + lastFourChars, {type: info.file.originFileObj.type})
    }
    setFile(newFile);
    getBase64(newFile as any, async (url) => {
      setImageUrl(url);
      form.setFieldValue('key', url);
      form.validateFields(['key']);
    });
  };
  return (
    <UploadImageWrapper>
      <Upload
        accept=".png, .jpg"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={() => handleChange}>
        <Button icon={<UploadOutlined />} className="btn-upload">
          Upload
        </Button>
      </Upload>
    </UploadImageWrapper>
  );
};

export default UploadImage;
