import { styled } from 'styled-components';

export const DetailCategoryWrapper = styled.div`
  background-color: #f7f7f7;
  flex: 1;
  padding: 24px;
  .content {
    position: relative;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131317;
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .list-style {
      border-top: 1px solid #eaeaea;
      padding: 15px 0px;
      .box-search {
        display: flex;
        justify-content: space-between;
        .input-search {
          padding: 14px;
          width: 400px;
          border-radius: 4px;
          border: 1px solid #eaeaea;
          input {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-left: 8px;
            &::placeholder {
              color: #8c8c92;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
        .group-btn {
          display: flex;
          gap: 15px;
          .edit-priority {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #f37f13;
            height: 51px;
            width: 200px;
            border: 1px solid #f37f13;
          }
        }
        .add-ai {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f37f13;
          border-radius: 8px;
          padding: 13px 24px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: white;
          gap: 18px;
          .plus {
            width: 20px;
            height: 20px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #f37f13;
            border-radius: 50%;
            font-size: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
`;

export const BackButton = styled.div`
  display: flex;
  padding: 12px 24px;
  border-radius: 8px;
  border: #f37f13 1px solid;
  color: #f37f13;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  gap: 16px;
  min-width: 220px;
  margin-left: 1rem;
`;
export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
