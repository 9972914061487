import { Input, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import {
  createCategory,
  getCategoryList,
  updateCategory,
  updatePriorityCategory,
} from '../../../../../services/categoryService';
import { ToastError, ToastSuccess } from '../../../../../components/toast-message/ToastMessage';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Sortable from './Sortable';

const SortableItem: any = SortableElement((props: any) => props.children);
const SortableList: any = SortableContainer((props: any) => props.children);

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 24px 32px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    .ant-btn-default {
      border-radius: 8px;
      background: var(--scale-light-scale-background, #f7f7f7);
      border: none;
      display: flex;
      width: 140px;
      height: max-content;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      span {
        color: var(--scale-gray-scale-high-emp, #131317);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        span {
          color: var(--scale-gray-scale-high-emp, #131317);
        }
      }
    }
    .ant-btn-primary {
      border-radius: 8px;
      background: var(--primary-primary, #f37f13);
      border: none;
      display: flex;
      width: 140px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      height: max-content;
      span {
        color: var(--scale-light-scale-white, #fff);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        background: var(--primary-primary, #f37f1390);
      }
    }
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Divider = styled.div`
  background: #cacaca;
  height: 1px;
  width: 100%;
`;

export const Title = styled.p`
  color: #333;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  margin-bottom: 0px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* align-items: center; */
`;

export const CategoryItem = styled.div``;

interface PropsType {
  open: boolean;
  onClose: () => void;
  refreshData: () => void;
  styleType?: string;
  property?: string;
}

export default function PriorityModal({
  open = true,
  onClose,
  refreshData,
  styleType,
  property,
}: PropsType) {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const segmentId = searchParams.get('segmentId') || '';
  const [categories, setCategories] = useState<any[]>([]);

  const mutationGetCategories = useMutation((params: any) => getCategoryList(params), {
    onSuccess: (res) => {
      let data = res?.data;
      if (data && data?.items) {
        setCategories(data?.items || []);
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const mutationUpdatePriority = useMutation((payload: any) => updatePriorityCategory(payload), {
    onSuccess: (res) => {
      if (res?.statusCode === 200) {
        onClose();
        refreshData();
        ToastSuccess('Category priority updated');
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const handleUpdateCategories = (categoriesUpdated: any[]) => {
    setCategories(categoriesUpdated);
  };

  const handleUpdatePriority = () => {
    const payload = {
      categories: categories.map((category, index) => ({ _id: category?._id, priority: index })),
      type: styleType,
      property,
    };
    mutationUpdatePriority.mutate(payload);
  };

  useEffect(() => {
    if (open) {
      mutationGetCategories.mutate({
        segmentId,
        projectId: id,
        orderBy: 'priority',
        orderType: 'asc',
        type: styleType,
        property,
      });
    }
  }, [open]);

  return (
    <ModalWrapper
      open={open}
      title={null}
      width={419}
      okText={'Update'}
      onCancel={onClose}
      onOk={handleUpdatePriority}
      centered
      closable={false}>
      <BodyWrapper>
        <Title>Edit Category Priority</Title>
        <Divider />
        {<Sortable items={categories} handleUpdateItems={handleUpdateCategories} />}
      </BodyWrapper>
    </ModalWrapper>
  );
}
