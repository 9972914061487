import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  .ant-input-show-count-suffix {
    display: flex;
    position: absolute;
    right:  10px;
    top: 30px;
    color: var(--scale-gray-scale-low-emp, #B9B9BF);
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const FrameLength = styled.div<{show: boolean}>`
  display: ${props => props.show ? 'flex' : 'none'};
  width: fit-content;
  justify-content: flex-end;
  z-index: 99;
  position: absolute;
  right: 10px;
  top: 60px;
  color: var(--scale-gray-scale-low-emp, #B9B9BF);
  font-family: Noto Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;