import { Input, Modal, Select } from 'antd';
import { styled } from 'styled-components';

export const ModalCreate = styled(Modal)`
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #F37F13;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #F37F13;
    background-color: #F37F13;
  }
  .ant-checkbox-checked:hover .ant-checkbox-inner{
    border-color: #F37F13 !important;
    background-color: #F37F13 !important;
  }
  .ant-modal-content {
    height: 90vh;
    overflow-y: auto;
  }
  .ant-select-selection-item {
    align-items: center !important;
  }
  .ant-select-arrow {
    display: flex !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-modal-content {
    padding: 30px 40px;
  }
  .ant-form-item-label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131317;
  }
  .group-btn {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 22px;
    .cancel-btn {
      width: 140px;
      height: 46px;
      background: #f7f7f7;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #131317;
      border: none;
    }
    .create-btn {
      width: 140px;
      height: 46px;
      background: #f37f13;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;
export const AddTag = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
export const SelectStyle = styled(Select)`
  .ant-select-clear-icon {
    font-size: 20px; /* Điều chỉnh kích thước tùy ý */
  }
`;

export const InputWrapper = styled(Input)`

`;

export const FrameOption = styled(Select.Option)`
  .ant-select-selection-item {
    align-items: center !important;
  }
`;

export const FrameLabel = styled.div`
  display: flex;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  background: #f0f0f0 !important;
`;
