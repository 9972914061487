import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStyleInfo {
    segment?: string;
    listImageSize?: any
}

interface IProps {
  styleInfo: IStyleInfo
}

const initialState: IProps = {
  styleInfo: {},
};

export const styleSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    setSegment: (state, action: PayloadAction<any>) => {
      state.styleInfo.segment = action.payload
    },
    setListImageSize: (state, action: PayloadAction<any>) => {
      state.styleInfo.listImageSize = action.payload
    }
  },
});

export const {
  setSegment,
  setListImageSize,
} = styleSlice.actions;
export default styleSlice.reducer;
