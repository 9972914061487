import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toastMessage } from '../../config/constants';
import { StyleType, styleTypes } from '../../constants';
import { addStyleToProject } from '../../services/detailProjectService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { BodyWrapper, Label, ModalCreateStyleProjectWrapper, StyleTypeWrapper } from './style';
import { getStyleInhouse } from '../../services/listStyleService';
import { subtractArrays } from '../../utils/helper';

const ModalCreateStyleProject = (props: any) => {
  const {
    segmentSelect,
    open,
    setOpen,
    projectName,
    params,
    setParams,
    setNameSearch,
    reloadAllStyle,
    listAllStyleInProject,
    listImageSize
  } = props;
    console.log("🚀 ~ file: index.tsx:25 ~ ModalCreateStyleProject ~ listImageSize:", listImageSize)

  const queryClient = useQueryClient();

  const [namesStyle, setNamesStyle] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [styles, setStyles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [styleType, setStyleType] = useState<string>(StyleType.ART);

  const listStyleMutation = useMutation(
    (params: any) => getStyleInhouse({ ...params, limit: 1000 }),
    {
      onSuccess: (res) => {
        if (res.data?.items) {
          const styleInProject = listAllStyleInProject?.data?.items
            ?.filter((item: any) => item?.mode === 'default' && item.styleType === styleType)
            .map((style: any) => {
              return {
                label: style.config.style,
                value: style.config.style,
              };
            });
          const styleInHouse = res.data.items
            .filter((item: any) => item.styleType === styleType)
            .map((style: any) => {
              return {
                label: style.config.style,
                value: style.config.style,
              };
            }) || [];
          const result = subtractArrays(styleInHouse, styleInProject);
          setStyles(result);
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    }
  );

  useEffect(() => {
    if (styleType && open) {
      setNamesStyle([]);
      listStyleMutation.mutate({ styleType });
    }
  }, [styleType, open]);

  const muatationAddStyleProject = useMutation((body: any) => addStyleToProject(body), {
    onSuccess: (data) => {
      handleCancel();
      setParams({ ...params, page: 1, name: '' });
      setNameSearch('');
      queryClient.invalidateQueries({
        queryKey: ['list-all-style-in-project'],
      });
      queryClient.invalidateQueries({
        queryKey: ['detail-project'],
      });
      reloadAllStyle();
      ToastSuccess(toastMessage.success);
      setLoading(false);
    },
    onError: () => {
      handleCancel();
      ToastError(toastMessage.error);
    },
  });

  const handleCancel = () => {
    setOpen(false);
    setLoading(false);
    setNamesStyle([]);
    setStyleType(StyleType.ART)
  };
  const handleBlur = () => {
    if (inputValue !== '') {
      setNamesStyle([...namesStyle, inputValue]);
      setInputValue('');
    }
  };
  const handleChangeSelect = (value: string[]) => {
    setNamesStyle(value);
  };
  const handleAddStyle = () => {
    setLoading(true);
    if (namesStyle.length !== 0) {
      let payload: any = {
        styles: namesStyle,
        project: projectName,
        styleType: styleType,
        imageSize: listImageSize[styleType],
      };
      if (segmentSelect) payload = { ...payload, segmentId: segmentSelect };
      muatationAddStyleProject.mutate(payload);
      console.log("🚀 ~ file: index.tsx:119 ~ handleAddStyle ~ payload:", payload)
    } else {
      setLoading(false);
      ToastError('Please select some style default');
    }
  };
  /*   const value = listStyleInhouse.data.items.map((item: any) => ({
    //item.config.style
  }) */

  return (
    <ModalCreateStyleProjectWrapper
      open={open}
      width={841}
      closable={false}
      footer={null}
      centered
      onCancel={handleCancel}>
      <BodyWrapper>
        <StyleTypeWrapper>
          <Label>Style type</Label>
          <Select
            value={styleType}
            size="large"
            style={{ width: '80%' }}
            onChange={(value) => {
              setStyleType(value);
            }}
            options={styleTypes}
          />
        </StyleTypeWrapper>
        <div className="content">
          <div className="title">AI style default</div>
          <Select
            value={namesStyle}
            size="large"
            mode="multiple"
            allowClear
            style={{ width: '80%' }}
            placeholder="Please select AI style default"
            defaultValue={[]}
            onBlur={handleBlur}
            onChange={handleChangeSelect}
            showSearch={true}
            >
            {styles?.map((option: any) => (
              <Select.Option value={option.value} label={option.label}>
                <div className="custom-option-select">{option.value}</div>
              </Select.Option>
            ))}
          </Select>
        </div>
      </BodyWrapper>
      <div className="footer">
        <Button className="btn-cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className="btn-add" onClick={handleAddStyle} loading={loading}>
          Add
        </Button>
      </div>
    </ModalCreateStyleProjectWrapper>
  );
};

export default ModalCreateStyleProject;
