import { Modal } from 'antd';
import { styled } from 'styled-components';

export const ModalEditStyle = styled(Modal)`
  .ant-modal-content {
    padding: 30px 40px;
  }
  .ant-form-item-label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #131317;
  }
  .footer {
    margin-top: 37px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    .btn-cancel {
      width: 140px;
      height: 46px;
      background: #f7f7f7;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #131317;
      border: none;
    }
    .btn-add {
      background: #f37f13;
      border-radius: 8px;
      width: 140px;
      height: 46px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      border: none;
    }
  }
`;
