import React, { useState } from 'react';
import { DrawerWrapper } from '../../../../ListStyle/style';
import { Button, Select } from 'antd';
import { propertyOption } from '../constant';
import IconCloseFilter from '../../../../../assets/images/icon-close-filter.svg';
import IcDownBlack from '../../../../../assets/icons/ic_down_back.svg';

interface PropsType {
  params: any;
  open: boolean;
  handleClose: () => void;
  handleApply: (value: any) => void;
}

export default function CategoryFilter({ params, open, handleClose, handleApply }: PropsType) {
  const [property, setProperty] = useState('');
  return (
    <DrawerWrapper placement="right" open={open} closable={false} width={480} onClose={handleClose}>
      <div>
        <div className="title-filter">
          <span>Advanced Filter</span>
          <img src={IconCloseFilter} alt="" onClick={handleClose} />
        </div>
        <div className="style">Category property</div>
        <Select
          suffixIcon={<img src={IcDownBlack} alt="down-icon" />}
          style={{ width: '100%' }}
          options={propertyOption}
          size="large"
          placeholder="none"
          value={property}
          onChange={(value) => {
            setProperty(value || '');
          }}
        />
      </div>
      <div className="footer">
        <span
          className="reset"
          onClick={() => {
            setProperty('');
          }}>
          Reset
        </span>
        <div>
          <Button
            className="btn-cancel"
            onClick={() => {
              setProperty(params?.property || '');
              handleClose();
            }}>
            Cancel
          </Button>
          <Button
            className="btn-apply"
            onClick={() => {
              handleApply({ property });
              handleClose();
            }}>
            Apply
          </Button>
        </div>
      </div>
    </DrawerWrapper>
  );
}
