import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  .ant-table-wrapper {
    margin-bottom: 20px !important;
  }

  .filter {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c92;
    margin-left: 8px;
  }
`;

export const FrameListTag = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 9px;
`;

export const FrameTag = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #f3f3f3;
`;

export const TxtTag = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
