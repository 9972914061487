import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ListProjectWrapper } from './style';
import Pagination from '../../components/Pagination';
import TableContent from '../../components/TableContent';
import { getListProject } from '../../services/listProjectService';
//import { getListStyle } from '../../services/listStyleService';

const ListProject = () => {
  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 120,
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'project',
      key: 'name',
      width: 370,
      render: (value: string, record: any) => {
        return(
          <div
            className="project-name"
            onClick={() => {
              sessionStorage.setItem('projectName', record.project);
              sessionStorage.setItem('projectId', record._id);
              navigate(`/projects/${record._id}`, {
                state: {
                  projectName: record.project,
                },
              })
            }}>
            {value || ''}
          </div>
        )
      },
    },
    {
      title: 'NUMBER OF STYLE',
      dataIndex: 'numStyles',
      key: 'numStyles',
      className: 'number-style',
      width: 300,
      render: (value: any) => value
    },
  ];

  const navigate = useNavigate();

  const [params, setParams] = useState<any>({
    limit: 15,
    page: 1,
  });

  
  const { isLoading, data: listProject } = useQuery(['get-list-project', params], () =>
    getListProject(params), 
    {
    onSuccess: (data) => {
        if (data.items?.length === 0 && params.page > 1) {
          setParams({ ...params, page: params.page - 1 });
        }
      }
    }
  );
  return (
    <ListProjectWrapper>
      <div className="content">
        <div className="title">Project</div>
        <div className="list-project">
          <TableContent
            loading={isLoading}
            dataSource={listProject?.data?.items}
            columns={columns}
            height="59vh"
            className="list-project-table"
          />
        </div>
        <Pagination
          limit={15}
          total={listProject?.data?.totalItems || 0}
          params={params}
          setParams={setParams}
        />
      </div>
    </ListProjectWrapper>
  );
};
export default ListProject;
