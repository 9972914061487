export const Style = [ 'AI Art', "AI Fashion", "AI Avatar"]
export const ImageSize = [
    {
        type: 'optional',
        value: '768'
    },
    {
        type: 'default',
        value: '960',
    },
    {
        type: 'optional',
        value: '1024'
    }
]

export const BaseModel = [
    {
        type: 'default',
        value: 'origin',
    },
    {
        type: 'optional',
        value: 'lcm'
    }
]