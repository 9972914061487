import { createBrowserRouter } from 'react-router-dom';
import Login from '../pages/login/Login';
import { ROUTES } from './routes';
import DefaultLayout from '../layout/DefaultLayout';
import ListStyle from '../pages/ListStyle';
import DetailProject from '../pages/DetailProject';
import ListProject from '../pages/ListProject';
import UserManage from '../pages/UserManagement';
import DetailCategory from '../pages/DetailCategory';

export const routerAdmin = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.HOME,
    element: <DefaultLayout />,
    children: [
      {
        element: <ListStyle />,
        path: ROUTES.STYLE,
      },
      {
        element: <ListProject />,
        path: ROUTES.PROJECT,
      },
      {
        element: <DetailProject />,
        path: ROUTES.PROJECT_DETAIL,
      },
      {
        element: <DetailCategory />,
        path: ROUTES.CATEGORY_DETAIL,
      },
      {
        element: <UserManage />,
        path: ROUTES.ACCOUNT,
      },
    ],
  },
]);

export const routerUser = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.HOME,
    element: <DefaultLayout />,
    children: [
      {
        element: <ListProject />,
        path: ROUTES.PROJECT,
      },
      {
        element: <DetailProject />,
        path: ROUTES.PROJECT_DETAIL,
      },
      {
        element: <DetailCategory />,
        path: ROUTES.CATEGORY_DETAIL,
      },
    ],
  },
]);
