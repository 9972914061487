import React from 'react';
import { IProps } from './interface';
import { Form, Select } from 'antd';
import { FormItemHorizontal } from './style'
export const SelectCustom = (props: IProps) => {
  const { label = '', name, rules = [], options, placeholder, onChange, wrapperCol, labelCol, horizontal = false } = props;

  return(
    !horizontal ? 
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      wrapperCol={wrapperCol}
      labelCol={labelCol}
      >
      <Select
        options={options} size="large"
        onChange={onChange}
        placeholder={placeholder}
      />
    </Form.Item> :
    <FormItemHorizontal
    label={label}
    name={name}
    rules={rules}
    wrapperCol={wrapperCol}
    labelCol={labelCol}
    >
      <Select
        options={options} size="large"
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormItemHorizontal>
  )
}