import { ShowImageUploadWrapper } from './style';

const ShowImageUpload = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <ShowImageUploadWrapper>
      {imageUrl ? (
        <img src={imageUrl} alt="avatar" />
      ) : (
        <div>
          <div>
            <span>PNG, JPG, MP4.</span>
          </div>
        </div>
      )}
    </ShowImageUploadWrapper>
  );
};

export default ShowImageUpload;
