import { Avatar, Button, Input, Modal, Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  DrawerWrapper,
  FrameAction,
  FrameEdit,
  FrameProject,
  FrameProjectRow,
  FrameResend,
  ListStyleWrapper,
  TxtRow,
  TxtRowEmail,
} from './style';
import Pagination from '../../components/Pagination';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import TableContent from '../../components/TableContent';
import { getListStyle, deleteStyle, getListStyleSelectors } from '../../services/listStyleService';
import { ToastError, ToastSuccess } from '../../components/toast-message/ToastMessage';
import IconSearch from '../../assets/images/icon-search.svg';
import IconFilter from '../../assets/images/icon-filter.svg';
import IconEditStyle from '../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../assets/images/icon-delete-style.svg';
import IconSelectFilter from '../../assets/images/icon-select-filter.svg';
import IconCloseFilter from '../../assets/images/icon-close-filter.svg';
import { toastMessage } from '../../config/constants';
import ModalAddMember from '../../components/ModalAddMember';
import authServices from '../../services/authService';
import { setUserInfo } from '../../redux/slices/authSlice';
import IcEdit from '../../assets/icons/ic_edit.svg';
import IcDelete from '../../assets/icons/ic_delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { checkPermissionEditAccount, checkResend } from '../../utils/roles';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ROLES, STATUS_VERIFY } from '../../constants';
import { getListProjects } from '../../services/AIStyleService';
import tokenAuthService from '../../utils/tokenAuthService';
import { capitalizeFirstLetter } from '../../utils/helper';

const UserManage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [modal, contextHolder] = Modal.useModal();
  const [optionProject, setOptionProject] = useState<any>([]);

  useEffect(() => {
    getListProjects().then((value: any) => {
      const data = value.map((project: any) => {
        return {label: project?.project, value: project?._id}
      });
      setOptionProject(data);

    })
  }, [])

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'username',
      key: 'username',
      width:130,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: 120,
      render: (text: string, row: any) => {
        return <TxtRowEmail active={row?.status === STATUS_VERIFY.ACTIVE}>{text}</TxtRowEmail>
      }
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      width: 100,
      render: (text: string, row: any) => {
        return <TxtRow active={row?.status === STATUS_VERIFY.ACTIVE}>{capitalizeFirstLetter(text)}</TxtRow>
      }
    },
    {
      title: 'PROJECT',
      dataIndex: 'projects',
      key: 'projects',
      width: 280,
      render: (value: any, row: any) => {
        const projects: any = value.map((id: string) => {
          const index = optionProject.findIndex((project: any) => project.value === id);
          if (index !== -1) return optionProject[index]?.label;
          return ;
        })
        if (row?.role === ROLES.ADMIN) return <FrameProject>{'All'}</FrameProject>
        return (
          <FrameProjectRow>
            {
              [...projects].slice(0, 2).map((project: any) => {
                return <FrameProject>{project}</FrameProject>
              })
            }
            {value.length > 2 && <FrameProject>{`+ ${value.length - 2}`}</FrameProject>}
          </FrameProjectRow>
        )
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      className: 'action',
      render: (text: string, row: any) => {
        return(
          <FrameAction>
            {
              checkResend(row?.status) &&
              <FrameResend>
                Resend
              </FrameResend>
            }
            {
              checkPermissionEditAccount(userInfo?.role, row?.role) &&
              <FrameEdit onClick={() => handleEditAccount(row)}>
                <img src={IcEdit}/>
              </FrameEdit>
            }
            <FrameEdit onClick={() => handleOpenDelete(row?._id)}>
              <img src={IcDelete}/>
            </FrameEdit>
          </FrameAction>);
      },
    },
  ];

  const [params, setParams] = useState<any>({
    limit: 10,
    page: 1,
    name: '',
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [idAccountDelete, setIdAccountDelete] = useState('');

  const [accounts, setAccounts] = useState({
    items: [],
    limit: 10,
    totalItems: 0
  });
  const [accountEdit, setAccountEdit] = useState(null);

  useEffect(() => {
    mutationGetAccounts.mutate(params);
  }, [])

  const mutationGetAccounts = useMutation(
    (payload: any) => authServices.getListAccounts(payload), {
    onSuccess: (res) => {
      let data = res?.data?.data;
      if (data) {
        setAccounts(data);
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const mutationDeleteAccount = useMutation(
    (id: string) => authServices.deleteAccount(id), {
      onSuccess: (res) => {
        ToastSuccess('Delete account success');
        if (idAccountDelete === userInfo?.id) {
          dispatch(setUserInfo({}));
          tokenAuthService.removeAuthUser();
          navigate('/login');
        } else {
          mutationGetAccounts.mutate(params);
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  const mutationAddNewAccount = useMutation(
    (payload: any) => authServices.addNewAccount(payload), {
      onSuccess: (res) => {
        ToastSuccess('Add new account success!');
        setOpenCreate(false);
        mutationGetAccounts.mutate(params);
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  const mutationUpdateAccount = useMutation(
    (payload: any) => authServices.updateAccount(payload), {
      onSuccess: (res) => {
        ToastSuccess('Update account success!');
        setOpenCreate(false);
        setAccountEdit(null);
        mutationGetAccounts.mutate(params);
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  useEffect(() => {
    mutationGetAccounts.mutate(params)
  }, [params])

  const handleEditAccount = (account: any) => {
    setAccountEdit(account);
    setOpenCreate(true);
  }

  const handleAddStyle = () => {
    setOpenCreate(true);
  };

  const handleOpenDelete = (id: string) => {
    setOpenDelete(true);
    setIdAccountDelete(id);
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
    setIdAccountDelete('');
  };

  const handleDelete = () => {
    setOpenDelete(false);
    mutationDeleteAccount.mutate(idAccountDelete);
  };

  const addNewAccount = (payload: any) => {
    mutationAddNewAccount.mutate(payload);
  }

  const updateAccount = (payload: any) => {
    mutationUpdateAccount.mutate(payload);
  }

  const closeModal = () => {
    setOpenCreate(false);
    setAccountEdit(null);
  }

  if (userInfo?.role !== ROLES.ADMIN) return <div/>;
  return (
    <ListStyleWrapper>
      <div className="content">
        <div className="title">Account management</div>
        <div className="list-style">
          <div className="box-search">
            <div className="add-ai" onClick={handleAddStyle}>
              <div className="plus">+</div>
              <div>Add Member</div>
            </div>
          </div>
          <TableContent
            columns={columns}
            dataSource={accounts?.items}
            height="55vh"
            loading={mutationGetAccounts.isLoading}
          />
        </div>
        <Pagination
          setParams={setParams}
          params={params}
          limit={accounts?.limit || 0}
          total={accounts?.totalItems || 0}
          showOption={false}
        />
      </div>
      {openCreate && (
        <ModalAddMember
          open={openCreate}
          closeModal={closeModal}
          addNewAccount={addNewAccount}
          updateAccount={updateAccount}
          accountEdit={accountEdit}
          optionProject={optionProject}
        />
      )}
      <ModalConfirmDelete
        open={openDelete}
        handleCancelDelete={handleCancelDelete}
        handleDelete={handleDelete}
        title={'Are you sure you want to remove \n this account?'}
        tittleDelete={'Remove'}
      />
      {contextHolder}
    </ListStyleWrapper>
  );
};
export default UserManage;
