import IconDrag from '../../../../../assets/images/icon-drag.svg';
import styled from 'styled-components';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { useEffect, useState } from 'react';

const SortableItem: any = SortableElement((props: any) => props.children);
const SortableList: any = SortableContainer((props: any) => props.children);

const Wrapper = styled.div`
  padding: 0 40px;
  max-height: 70vh;
  overflow-y: auto;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* align-items: center; */
`;

const SortItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  position: relative;
  z-index: 10000;
  cursor: pointer;
  p {
    margin-bottom: 0px;
    word-break: break-all;
    color: var(--scale-gray-scale-high-emp, #131317);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

interface PropsType {
  items: any[];
  handleUpdateItems: (items: any[]) => void;
}

const Sortable = ({ items, handleUpdateItems }: PropsType) => {
  const [sortableItems, setSortableItems] = useState<any[]>([]);
  const handleSortEnd = (data: any) => {
    const { oldIndex, newIndex } = data;
    const newItems = arrayMove(sortableItems, oldIndex, newIndex);
    setSortableItems(newItems);
    handleUpdateItems(newItems);
  };

  useEffect(() => {
    if (items.length) {
      setSortableItems(items);
    }
  }, [JSON.stringify(items)]);

  return (
    <Wrapper>
      <SortableList onSortEnd={handleSortEnd}>
        <ListWrapper>
          {sortableItems.map((value, index) => (
            <SortableItem key={value?._id} index={index}>
              <SortItemWrapper>
                <img src={IconDrag} alt="icon-drag" />
                <p>{value?.name}</p>
              </SortItemWrapper>
            </SortableItem>
          ))}
        </ListWrapper>
      </SortableList>
    </Wrapper>
  );
};

export default Sortable;
