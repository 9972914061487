import { Form, Input, Modal } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  top: 290px;
  .ant-modal-title {
    color: var(--scale-gray-scale-high-emp, #131317);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }
  .ant-modal-content {
    padding: 24px 32px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn-default {
      border-radius: 8px;
      background: var(--scale-light-scale-background, #f7f7f7);
      border: none;
      display: flex;
      width: 140px;
      height: max-content;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      span {
        color: var(--scale-gray-scale-high-emp, #131317);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        span {
          color: var(--scale-gray-scale-high-emp, #131317);
        }
      }
    }
    .ant-btn-primary {
      border-radius: 8px;
      background: var(--primary-primary, #f37f13);
      border: none;
      display: flex;
      width: 140px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      height: max-content;
      span {
        color: var(--scale-light-scale-white, #fff);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        background: var(--primary-primary, #f37f1390);
      }
    }
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px 0px;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-control {
    border-left: 1px solid var(--scale-light-scale-line, #eaeaea);
    padding-left: 12px;
  }
  .ant-form-item-label-left {
    display: flex;
    align-items: center;
    label {
      color: var(--scale-gray-scale-high-emp, #131317);
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
  .ant-select {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100% !important;
    line-height: 14px !important;
    height: 100%;
    background: var(--scale-light-scale-white, #fff);
    height: max-content;
    .ant-select-selector {
      border-radius: 4px;
      padding: 10px 16px !important;
      width: 100% !important;
    }
  }
  .ant-select-arrow {
    svg {
      g {
        path {
          fill: #131317;
        }
      }
    }
  }
`;
