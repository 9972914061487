import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { IProps } from './interface';
import { FrameLength, Wrapper } from './styles';
import TextArea from 'antd/es/input/TextArea';

export const InputCustom = (props: IProps) => {
  const { label = '', name, rules = [], placeholder, maxLength, showCount = false } = props;

  return (
    <Wrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
      >
        <Input
          name={name} size="large"
          placeholder={placeholder}
          maxLength={maxLength}
          showCount={showCount}
        />
      </Form.Item>
    </Wrapper>
  );
};