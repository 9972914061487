import { Button, Form, Input, Select, message, Tag, Checkbox } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchPresignUrl, uploadImageApi } from '../../services/AIStyleService';
import { FrameLabel, FrameOption, InputWrapper, ModalCreate } from './style';
import UploadImage from '../UploadImage';
import 'antd-tag-input/dist/style.css';
import ShowImageUpload from '../ShowImageUpload';
import {
  createStyle,
  createStyleCustom,
  createStyleInhouse,
  getListStyleSelectors,
  getStyleInhouse,
  getStyleProject,
  updateStyle,
} from '../../services/listStyleService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';
import TagInput from 'antd-tag-input';
import InputTag from './InputTag';
import { set } from 'lodash';
import {
  Gender,
  STYLE_MODE,
  StyleType,
  SubScriptOption,
  genderOptions,
  styleTypes,
  SortOption,
  StyleConfigMode,
  KEY_NUMBER,
  KEY_BOOLEAN,
  AI_FAMILY_DEFAULT,
  DEFAULT_IMAGE_SIZE,
  baseModels,
  BASE_MODEL_DEFAULT,
  BaseModelConfig,
} from '../../constants';
import IcUploadBlack from './../../assets/icons/ic_upload_black.svg';
import { ThumbnailApp } from './component/ThumbnailApp';
import { getThumbnailType } from '../../services/listProjectService';
import { IThumbnail, ThumbnailOption } from './component/ThumbnailApp/interface';
import { Thumbnail } from './component/Thumbnail';
import IcDownBlack from '../../assets/icons/ic_down_back.svg';
import { getAiFamilyList } from '../../services/aiFamilyService';
import { getCategoryList } from '../../services/categoryService';
import { useParams } from 'react-router-dom';
import { CategoryType } from '../../pages/DetailProject/component/Category/constant';
import { convertFileUpload, convertStyleTyleToCategoryType } from '../../utils/helper';

const convertBase64ToFile = function (image: string) {
  const byteString = atob(image.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: 'image/jpeg',
  });
  return newBlob;
};

interface IProps {
  reload: (props: any) => void | Promise<void>;
  styleItem: any;
  open: boolean;
  setOpen: (params: boolean) => void;
  resetStyleItem: () => void;
}

interface ConfigData {
  key: string;
  thumbnailUrl: string;
}

const ModalCustomStyleProject = (props: any) => {
  const queryClient = useQueryClient();
  const {
    styleEdit,
    segmentSelect,
    setStyleEdit,
    open,
    setOpen,
    params,
    setParams,
    setNameSearch,
    setStyleFilter,
    listAddStyle,
    name,
    detailProject,
    reloadAllStyle,
    listImageSize,
  } = props;
  const projectId = sessionStorage.getItem('projectId') || '';
  const projectName = sessionStorage.getItem('projectName' || '');
  const { id } = useParams();

  const [file, setFile] = useState<UploadChangeParam | any>({ name: '' });
  const [imageUrl, setImageUrl] = useState<string>('');
  const [allNameStyle, setAllNameStyle] = useState<any>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [aiFamilyList, setAiFamilyList] = useState<any[]>([]);
  const [form] = useForm();
  const styleType = useWatch('styleType', form);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [thumbnailTypeOption, setThumbnailTypeOption] = useState<ThumbnailOption[]>([]);
  const [categoryOption, setCategoryOption] = useState([]);

  useEffect(() => {
    getDataThumbnailType();
  }, []);

  const getListThumbnailType = useMutation(() => getThumbnailType(projectId), {
    onSuccess: (res: any) => {
      if (res?.data) {
        const data = res?.data?.thumbnailType || [];
        const dataConvert = data.map((item: string, index: number) => {
          return { label: item, value: item };
        });
        setThumbnailTypeOption(dataConvert);
      }
    },
    onError: () => {},
  });

  const getAiFamily = useMutation((parmas: any) => getAiFamilyList(parmas), {
    onSuccess: (res: any) => {
      if (res?.items) {
        setAiFamilyList(
          res.items.map((item: any) => ({
            label: item.displayName || '',
            value: item?.name,
            key: item?._id,
          }))
        );
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (open) {
      getAiFamily.mutate({ page: 1, limit: 100 });
    }
  }, [open]);

  useEffect(() => {
    if (!styleEdit) {
      form.setFieldValue('styleType', StyleType.ART);
    } else {
      console.log(styleEdit.styleType);
      form.setFieldValue('styleType', styleEdit.styleType || StyleType.ART);
    }
  }, [JSON.stringify(styleEdit)]);

  const getDataThumbnailType = () => {
    getListThumbnailType.mutate();
  };

  const { data: categoryList } = useQuery(
    ['get-category', styleType],
    () => {
      let categoryTypes = convertStyleTyleToCategoryType(styleType);

      return getCategoryList({
        segmentId: segmentSelect,
        projectId: id,
        type: categoryTypes,
      });
    },
    {
      onSuccess: (res: any) => {
        if (res?.data?.items) {
          const option = res?.data?.items?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }));
          setCategoryOption(option);
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    }
  );

  const { data: listStyleInhouse } = useQuery(
    ['get-style-inhouse', styleType],
    () => getStyleInhouse({ limit: 500, styleType: styleType }),
    {
      onSuccess: (res: any) => {
        const listStyleSelect = res?.data?.items.map((item: any) => ({
          label: item?.config?.style,
          value: item?.config?.style,
        }));
        setAllNameStyle(listStyleSelect);
      },
    }
  );

  const mutationCreateStyle = useMutation((body: any) => createStyleCustom(body), {
    onSuccess: (data) => {
      handleCancel();
      setParams({ ...params, page: 1, name: '', styles: '' });
      setNameSearch('');
      queryClient.invalidateQueries({
        queryKey: ['detail-project'],
      });
      queryClient.invalidateQueries({
        queryKey: ['list-all-style-in-project'],
      });
      reloadAllStyle();
      ToastSuccess('Create style successfully');
      setLoading(false);
    },
    onError: (error: any) => {
      queryClient.invalidateQueries({
        queryKey: ['detail-project'],
      });
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      handleCancel();
    },
  });

  const mutationUpdateStyle = useMutation((body: any) => updateStyle(body, styleEdit._id), {
    onSuccess: (data) => {
      handleCancel();
      reloadAllStyle();
      queryClient.invalidateQueries({
        queryKey: ['list-all-style-in-project'],
      });
      queryClient.invalidateQueries({
        queryKey: ['detail-project'],
      });
      queryClient.invalidateQueries({
        queryKey: ['get-list-style-selectors'],
      });
      ToastSuccess(toastMessage.success);
      setLoading(false);
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      handleCancel();
    },
  });

  useEffect(() => {
    if (styleType === StyleType.AI_FASHION && !styleEdit) {
      form.setFieldsValue({
        config: {
          fixOpenpose: true,
        },
      });
    }
  }, [styleType, JSON.stringify(styleEdit)]);

  useEffect(() => {
    if (styleEdit) {
      setImageUrl(styleEdit.key);
      setSelectedOptions(styleEdit.metadata || []);
    }
  }, [styleEdit]);

  const onFinish = async (values: any) => {
    const { key } = values;
    try {
      const thumbnailApp = [];
      let thumbnail = key;
      setLoading(true);
      if (key?.isImage !== undefined || null) {
        console.log('newww fileeee', convertFileUpload(key?.thumbnail?.name));

        const presign = await fetchPresignUrl(convertFileUpload(key?.thumbnail?.name));
        const upload = await uploadImageApi(presign.thumbnailPresign, key?.thumbnail);
        if (upload?.status !== 200) {
          ToastError('Upload thumbnail fail!');
          return;
        }
        thumbnail = presign.filepath;
      }
      if (values.thumbnailApp?.length) {
        for (const item of values.thumbnailApp) {
          if (item?.isImage !== undefined || null) {
            const presign = await fetchPresignUrl(convertFileUpload(key?.thumbnail?.name));
            const upload = await uploadImageApi(presign.thumbnailPresign, item?.thumbnail);
            if (upload?.status !== 200) break;
            thumbnailApp.push({
              thumbnail: presign.filepath,
              thumbnail_type: item?.thumbnail_type,
            });
          } else {
            thumbnailApp.push(item);
          }
        }
      }
      if (thumbnailApp?.length < values.thumbnailApp?.length) {
        ToastError('Presign Link Error, Try Again!');
        return;
      }
      let config: any = {
        mode: StyleConfigMode[values.styleType],
        imageSize: listImageSize ? listImageSize[values.styleType] : DEFAULT_IMAGE_SIZE,
        baseModel: values.baseModel,
      };
      Object.keys(values.config).forEach((key) => {
        let value = values.config[key];
        if (Number(values.config[key]) && KEY_NUMBER.includes(key)) {
          value = Number(values.config[key]);
        } else if (KEY_BOOLEAN.includes(key)) {
          value = !!value;
        }
        config[key] = value;
      });
      let body = {
        ...values,
        key: thumbnail,
        config,
        metadata: selectedOptions,
        project: projectName,
        thumbnailApp: thumbnailApp,
      };
      if (segmentSelect) body = { ...body, segmentId: segmentSelect };
      const params = {
        ...body,
        metadata: selectedOptions,
        project: projectName,
      };
      delete params.segmentId;
      if (!styleEdit?.key) {
        mutationCreateStyle.mutate(body);
      } else {
        mutationUpdateStyle.mutate(params);
      }
    } catch (error) {
      console.log('🚀 ~ file: index.tsx:354 ~ onFinish ~ error:', error);
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: 'Internal Server',
      });
      return;
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFile({ name: '' });
    setImageUrl('');
    setOpen(false);
    setStyleEdit(null);
    setLoading(false);
  };
  const handleSelectStyle = (name: any) => {
    const styleSelect = listStyleInhouse.data.items.find(
      (style: any) => style.config.style === name
    );
    if (styleSelect) {
      const config = styleSelect?.config;
      form.setFieldValue('config', config);
    } else {
      form.setFieldValue('config', {});
    }
  };

  const handleCreateStyle = () => {
    form.submit();
  };

  const active = useMemo(() => {
    return styleEdit?.mode !== STYLE_MODE.DEFAULT;
  }, []);
  const nonActiveCustom = useMemo(() => {
    return styleEdit?.mode !== STYLE_MODE.CUSTOM;
  }, []);
  const categoriesConvert = useMemo(() => {
    return styleEdit?.categories?.map((item: any) => item?._id);
  }, [styleEdit?.categories]);

  const handleChangeStyleType = (value: string) => {
    const values = form.getFieldsValue();
    form.resetFields();
    form.setFieldsValue({
      styleType: values?.styleType,
    });
  };

  return (
    <>
      <ModalCreate
        width={841}
        closable={false}
        footer={null}
        centered
        open={open}
        onCancel={handleCancel}>
        {contextHolder}
        <Form
          requiredMark={true}
          colon={false}
          form={form}
          name="createStyle"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{
            name: styleEdit?.name || '',
            config: styleEdit?.config || {},
            thumbnail: styleEdit?.key || '',
            metadata: styleEdit?.metadata || {},
            thumbnailApp: styleEdit?.thumbnailApp || [],
            subscriptionType: styleEdit?.subscriptionType || SubScriptOption[0].value,
            categories: categoriesConvert || [],
            key: styleEdit?.key || '',
            aiFamily: styleEdit?.aiFamily?.name || AI_FAMILY_DEFAULT,
            baseModel: styleEdit?.baseModel || BASE_MODEL_DEFAULT,
          }}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item
            label="AI style name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name of style!',
                type: 'string',
              },
              {
                max: 30,
                message: 'The name of the style cannot be longer than 30 characters!',
              },
              {
                validator: (_, value) => {
                  if (value && value.trim().length === 0) {
                    return Promise.reject('The name of the style cannot contain only spaces!');
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (!styleEdit) {
                    if (name?.includes(value)) {
                      return Promise.reject('Style name must be unique!');
                    }
                  }
                  if (styleEdit) {
                    if (value !== styleEdit.name) {
                      if (name?.includes(value)) {
                        return Promise.reject('Style name must be unique!');
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <Input
              name="name"
              size="large"
              className="name-input"
              placeholder="Anime"
              maxLength={30}
              onKeyDown={(e) => {
                const input = e.target as HTMLInputElement;
                const value = input.value;
                if (value.length >= 30) {
                  // Ngăn chặn việc nhập ký tự nếu đã đạt đến giới hạn 30 ký tự
                  if (e.key !== 'Backspace' && e.key !== 'Delete') {
                    e.preventDefault();
                  }
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="Style"
            name={['config', 'style']}
            rules={[
              {
                required: true,
                message: 'Please select style!',
                type: 'string',
              },
            ]}>
            <Select
              showSearch
              suffixIcon={<img src={IcDownBlack} />}
              options={allNameStyle}
              size="large"
              filterOption={(inputValue: any, option: any) =>
                option?.label?.toLowerCase().includes(inputValue?.toLowerCase())
              }
              onChange={handleSelectStyle}
              placeholder="none"
            />
          </Form.Item>

          <Form.Item
            label="Style type"
            name={'styleType'}
            rules={[
              {
                required: true,
                message: 'Please select style type!',
                type: 'string',
              },
            ]}>
            <Select
              suffixIcon={styleEdit && nonActiveCustom ? null : <img src={IcDownBlack} />}
              options={styleTypes}
              size="large"
              placeholder="none"
              disabled={!!styleEdit && nonActiveCustom}
              onChange={handleChangeStyleType}
            />
          </Form.Item>
          <Form.Item
            label="Base Model"
            name={'baseModel'}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select ai family!',
            //     type: 'string',
            //   },
            // ]}
          >
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={baseModels}
              disabled={!!styleEdit && nonActiveCustom}
              size="large"
              placeholder="none"
            />
          </Form.Item>

          <Form.Item label="AI family" name={'aiFamily'}>
            <Select
              suffixIcon={styleEdit && nonActiveCustom ? null : <img src={IcDownBlack} />}
              options={aiFamilyList}
              size="large"
              placeholder="none"
              disabled={!!styleEdit && nonActiveCustom}
            />
          </Form.Item>

          {styleTypes
            .find((type) => type.value === styleType)
            ?.fields.map((field) => {
              switch (field) {
                case 'positivePrompt': {
                  return (
                    <Form.Item
                      label="Positive prompt"
                      name={['config', 'positivePrompt']}
                      rules={[
                        () => ({
                          validator(_, value?: any) {
                            if (value) {
                              if (typeof value !== 'string') {
                                return Promise.reject('Positive prompt must be string');
                              } else if (value.length > 1000) {
                                return Promise.reject('Max length of Positive prompt is 1000');
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}>
                      <InputWrapper
                        name="config.positivePrompt"
                        size="large"
                        maxLength={1000}
                        disabled={!active}
                        // style={{ pointerEvents: 'none' }}
                        onKeyDown={(e) => {
                          const input = e.target as HTMLInputElement;
                          const value = input.value;
                          if (value.length >= 1000) {
                            // Ngăn chặn việc nhập ký tự nếu đã đạt đến giới hạn 30 ký tự
                            if (e.key !== 'Backspace' && e.key !== 'Delete') {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  );
                }
                case 'negativePrompt': {
                  return (
                    <Form.Item
                      label="Negative prompt"
                      name={['config', 'negativePrompt']}
                      rules={[
                        () => ({
                          validator(_, value?: any) {
                            if (value) {
                              if (typeof value !== 'string') {
                                return Promise.reject('Negative prompt must be string');
                              } else if (value.length > 1000) {
                                return Promise.reject('Max length of Negative prompt is 1000');
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}>
                      <InputWrapper
                        name="config.negativePrompt"
                        size="large"
                        maxLength={1000}
                        disabled={!active}
                        onKeyDown={(e) => {
                          const input = e.target as HTMLInputElement;
                          const value = input.value;
                          if (value.length >= 1000) {
                            // Ngăn chặn việc nhập ký tự nếu đã đạt đến giới hạn 30 ký tự
                            if (e.key !== 'Backspace' && e.key !== 'Delete') {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  );
                }
                case 'alpha': {
                  return (
                    <Form.Item
                      label="Alpha"
                      name={['config', 'alpha']}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value && (value > 1 || value < 0)) {
                              return Promise.reject('Alpha must be in 0 -> 1');
                            }
                            if (value && (value < 1 || value > 0) && value.toString().length > 4)
                              return Promise.reject('Alpha must be in the format 0.xx');
                            return Promise.resolve();
                          },
                        }),
                        () => ({
                          validator(_, value) {
                            if (value && value == 0) {
                              if (!/^0$/.test(value)) {
                                /*return Promise.resolve();
                    } else { */
                                return Promise.reject(
                                  'If the value is 0, then there should be only 1 digit 0'
                                );
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                        // {
                        //     pattern: new RegExp(/^[0-9]*$/),
                        //     message: "Strength chỉ có thể là số nguyên",
                        // },
                      ]}>
                      <InputWrapper
                        disabled={!active}
                        type="number"
                        name="config.alpha"
                        step={0.1}
                        size="large"
                        placeholder="0 -> 1"
                        onKeyDown={(evt) =>
                          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </Form.Item>
                  );
                }
                case 'strength': {
                  return (
                    <Form.Item
                      label="Strength"
                      name={['config', 'strength']}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value && (value > 1 || value < 0)) {
                              return Promise.reject('Strength must be in 0 -> 1');
                            }
                            if (value && (value < 1 || value > 0) && value.toString().length > 4)
                              return Promise.reject('Strength must be in the format 0.xx');
                            return Promise.resolve();
                          },
                        }),
                        () => ({
                          validator(_, value) {
                            if (value && value == 0) {
                              if (!/^0$/.test(value)) {
                                /*return Promise.resolve();
                    } else { */
                                return Promise.reject(
                                  'If the value is 0, then there should be only 1 digit 0'
                                );
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                        // {
                        //     pattern: new RegExp(/^[0-9]*$/),
                        //     message: "Strength chỉ có thể là số nguyên",
                        // },
                      ]}>
                      <InputWrapper
                        type="number"
                        name="config.strength"
                        step={0.1}
                        disabled={!active}
                        size="large"
                        placeholder="0 -> 1"
                        onKeyDown={(evt) =>
                          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </Form.Item>
                  );
                }
                case 'guidanceScale': {
                  return (
                    <Form.Item
                      label="Guidance scale"
                      name={['config', 'guidanceScale']}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value && (value > 10 || value < 0)) {
                              return Promise.reject('Guidance scale must be in 0 -> 10');
                            }
                            return Promise.resolve();
                          },
                        }),
                        () => ({
                          validator(_, value) {
                            if (value && value == 0) {
                              if (!/^0$/.test(value)) {
                                /*return Promise.resolve();
                    } else { */
                                return Promise.reject(
                                  'If the value is 0, then there should be only 1 digit 0'
                                );
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                        {
                          pattern: new RegExp(/^[0-9-]*$/),
                          message: 'Guidance scale can only be an integer',
                        },
                      ]}>
                      <InputWrapper
                        type="number"
                        disabled={!active}
                        name="config.guidanceScale"
                        step={1}
                        size="large"
                        placeholder="0 -> 10"
                        onKeyDown={(evt) =>
                          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </Form.Item>
                  );
                }
                case 'numInferenceSteps': {
                  return (
                    <Form.Item
                      label="Step"
                      name={['config', 'numInferenceSteps']}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value && (value > 100 || value < 0)) {
                              return Promise.reject('Step must be in 0 -> 100');
                            }
                            return Promise.resolve();
                          },
                        }),
                        {
                          pattern: new RegExp(/^[0-9-]*$/),
                          message: 'Step can only be an integer',
                        },
                        () => ({
                          validator(_, value) {
                            if (value && value == 0) {
                              if (!/^0$/.test(value)) {
                                /*return Promise.resolve();
                    } else { */
                                return Promise.reject(
                                  'If the value is 0, then there should be only 1 digit 0'
                                );
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}>
                      <InputWrapper
                        disabled={!active}
                        type="number"
                        name="config.step"
                        size="large"
                        placeholder="0 -> 100"
                        onKeyDown={(evt) =>
                          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </Form.Item>
                  );
                }
                case 'fixOpenpose': {
                  return (
                    <Form.Item
                      label="Fix openpose"
                      name={['config', 'fixOpenpose']}
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                  );
                }
                case 'gender': {
                  return (
                    <Form.Item label="Gender" name={['config', 'gender']}>
                      <Select
                        defaultValue={Gender.MALE}
                        suffixIcon={<img src={IcDownBlack} />}
                        options={genderOptions}
                        size="large"
                        placeholder="Select gender"
                      />
                    </Form.Item>
                  );
                }
                default:
                  return null;
              }
            })}

          <Form.Item label="Category" name={'categories'}>
            <Select
              suffixIcon={styleEdit ? null : <img src={IcDownBlack} />}
              size="large"
              placeholder="none"
              mode="multiple"
              allowClear
              disabled={styleEdit}
              filterOption={(inputValue: any, option: any) =>
                option?.children?.props.children?.toLowerCase().includes(inputValue?.toLowerCase())
              }>
              {categoryOption.map((option: any) => (
                <FrameOption value={option?.value}>
                  <FrameLabel>{option?.label}</FrameLabel>
                </FrameOption>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Subscription"
            name="subscriptionType"
            rules={[
              {
                required: true,
                message: 'Please select Subscription type!',
                type: 'string',
              },
            ]}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={SubScriptOption}
              size="large"
              placeholder="Select subscription"
            />
          </Form.Item>
          <Form.Item label="Property" name="metadata">
            <InputTag selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
          </Form.Item>
          <ThumbnailApp
            thumbnailTypeOption={thumbnailTypeOption}
            styleEdit={styleEdit}
            getDataThumbnailType={getDataThumbnailType}
            form={form}
          />
          <Thumbnail form={form} />
          <div className="group-btn">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="create-btn" onClick={handleCreateStyle} loading={loading}>
              {styleEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      </ModalCreate>
    </>
  );
};

export default ModalCustomStyleProject;
