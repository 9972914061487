import React, {useEffect} from "react";
import {
    ButtonWrapper, FrameCenter, FrameImg,
    FrameLogin, FrameLogo, TxtName, TxtTitle,
    Wrapper
} from "./styles";
import tokenAuthService from "../../utils/tokenAuthService";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from './../../assets/icons/logo.svg';
import { ToastError } from '../../components/toast-message/ToastMessage';
import { useMutation } from 'react-query';
import authServices from "../../services/authService";
import { setUserInfo } from "../../redux/slices/authSlice";

const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        const error = query.get('error') || '';
        const errorMessage = query.get('errorMessage') || '';
        if (token) {
            tokenAuthService.setLocalAccessToken(token);
            mutationGetProfile.mutate();
        } else if (error || errorMessage) {
            ToastError(error || errorMessage);
        }
    }, [location])

    const mutationGetProfile = useMutation(() => authServices.getProfile(), {
        onSuccess: (res) => {
            let data = res?.data?.data;
            if (data) {
                data = {...data, id: data._id};
                delete data._id;
                dispatch(setUserInfo(data));
                navigate('/');
            }
        },
        onError: (error: any) => {
            tokenAuthService.removeAuthUser();
            ToastError('Your email is not allowed to access. Please contact admin to create an account!');
        },
    });

    const loginWithGoogle = () => {
        const newPath = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        const redirectRoute = `${process.env.REACT_APP_AUTHEN_BASE_URL}/google?redirect_url=${newPath}&user_type=${process.env.REACT_APP_USER_TYPE}&platform=google`;
        window.location.href = redirectRoute;
    }

    return(
        <Wrapper>
            <FrameLogo>
                <FrameImg src={Logo}/>
            </FrameLogo>
            <FrameLogin>
                <TxtTitle>{'Visionlab Backend'}</TxtTitle>
                <FrameCenter>
                    <TxtName>{'AI style management'}</TxtName>
                    <ButtonWrapper onClick={loginWithGoogle}>Log in with Google Account</ButtonWrapper>
                </FrameCenter>
            </FrameLogin>
        </Wrapper>
    )
}

export default Login;