import httpService from './../config/http.service';
import { API_MAIN, APIs, VERSION } from '../utils/api';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getListProject = async (params: any) => {
  const res = await httpService(authUrl).get(VERSION.V3 + APIs.LIST_PROJECT, { params });
  return res?.data;
};

export const getThumbnailType = async (projectId: string) => {
  const res = await httpService(authUrl).get(VERSION.V3 + APIs.THUMBNAIL_TYPE + `/${projectId}`);
  return res?.data;
};

export const createThumbnailType = async (payload: any) => {
  const res = await httpService(authUrl).post(VERSION.V3 + APIs.THUMBNAIL_TYPE, payload);
  return res?.data;
};
