import { Button, Input, Select } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const SearchCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
`;

export const FilterButton = styled(Button)`
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--scale-light-scale-line, #eaeaea);
  background: var(--scale-light-scale-white, #fff);
  span {
    color: #8c8c92;
  }
`;

export const UpdateCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SearchInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid var(--scale-light-scale-line, #eaeaea);
  background: var(--scale-light-scale-white, #fff);
  display: flex;
  max-width: 300px;
  height: 48px;
  padding: 4px 4px 4px 12px;
  align-items: center;
  gap: 12px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .category-name {
    color: #1395f3;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SelectWrapper = styled.div`
  .ant-select {
    height: max-content !important;
    width: max-content;
  }

  .ant-select-selector {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #f37f13 !important;
    padding: 0px 24px !important;
    height: 46px !important;
  }
  .ant-select-selection-item {
    color: #f37f13;

    /* Body/ Semi */
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }
  .ant-select-arrow {
    display: block !important;
    top: 40%;
    height: max-content;
  }
`;
