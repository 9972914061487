import { Pagination as PaginationAntd, Select } from 'antd';
import { PaginationWrapper } from './style';
import IconPrevAll from '../../assets/images/icon-prev-all.svg';
import IconNextAll from '../../assets/images/icon-next-all.svg';
import IconSelectLimt from '../../assets/images/icon-select-limit.svg';
import IconDisableNextAll from '../../assets/images/icon-disable-next-all.svg';
import IconDisablePrevAll from '../../assets/images/icon-disable-prev-all.svg';

interface IPropsPagination {
  limit: number;
  total: number;
  params: any;
  setParams: any;
  showOption?: boolean;
}

const selectLimit = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
];

const Pagination = (props: IPropsPagination) => {
  const { limit = 15, total, params, setParams, showOption = true } = props;

  const maxPage = Math.ceil(total / limit);

  const handleChangePage = (page: number) => {
    setParams({ ...params, page: page });
  };

  const handleChangeLimit = (limit: number) => {
    setParams({ ...params, limit, page: 1 });
  };

  const handlePrevAll = () => {
    if (total === 0 || params.page === 1) return;
    setParams({ ...params, page: 1 });
  };

  const handleNextAll = () => {
    if (total === 0 || limit * params.page >= total) return;
    setParams({ ...params, page: maxPage });
  };

  return (
    <PaginationWrapper>
      <div className="pagination">
        <img
          onClick={handlePrevAll}
          src={total === 0 || params.page === 1 ? IconDisablePrevAll : IconPrevAll}
          alt=""
          className={`icon-all ${total === 0 || (params.page === 1 && 'icon-disable')}`}
        />
        <PaginationAntd
          pageSize={total > 0 ? limit : 0}
          total={total}
          onChange={handleChangePage}
          current={params.page}
        />
        <img
          onClick={handleNextAll}
          src={total === 0 || limit * params.page >= total ? IconDisableNextAll : IconNextAll}
          alt=""
          className={`icon-all ${total === 0 || (limit * params.page >= total && 'icon-disable')}`}
        />
      </div>
      <div className="show-info-paganation">
        <span>
          Show {total > 0 ? limit * (params.page - 1) + 1 : 0} to{' '}
          {total === 0 ? 0 : limit * params.page >= total ? total : limit * params.page} of {total}{' '}
          items
        </span>

        {showOption && (
          <Select
            value={limit}
            style={{ width: 60 }}
            onChange={handleChangeLimit}
            options={selectLimit}
            suffixIcon={<img src={IconSelectLimt} alt="" />}
          />
        )}
        {showOption && <span>Items per page</span>}
      </div>
    </PaginationWrapper>
  );
};

export default Pagination;
