export const EXCEPTION_ERROR_MESSAGE = 'An exception error occurred.';

export const DEFAULT_ERROR_MESSAGE = 'Error';

export const toastMessage = {
  success: 'Update successfully',
  error: 'Update fail',
};

export const SUSCRIPTION_TYPE: any = {
  free: 'Free',
  ad: 'Ad',
  premium: 'Premiumn',
  adOrPremium: 'Ad or Premium',
};
