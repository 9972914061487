/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Select } from 'antd';
import { uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { BodyWrapper } from '../../../components/ModalCreateStyleProject/style';
import { ToastError, ToastSuccess } from '../../../components/toast-message/ToastMessage';
import { styleTypes } from '../../../constants';
import { addStylesToCategory, updateCategory } from '../../../services/categoryService';
import { getAllStyle } from '../../../services/listStyleService';
import { convertCategoryTypeToStyleTyle } from '../../../utils/helper';
import { FormItem, ModalWrapper } from './styles';

interface PropsType {
  open: boolean;
  onClose: () => void;
  category?: any;
  refreshData: (styleIds: string[]) => void;
}

export default function AddStyleModal({ open = true, onClose, category, refreshData }: PropsType) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [stylesOption, setStylesOption] = useState<any>([]);
  const styleTypesWatch = Form.useWatch('style-types', form) || [];
  const stylesWatch = Form.useWatch('styles', form) || [];

  const mutationGetStyles = useMutation((params: any) => getAllStyle(params), {
    onSuccess: (res) => {
      setStylesOption(res || []);
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const mutationAddStylesToCategory = useMutation(
    (styleIds: string[]) => addStylesToCategory(category?._id, styleIds),
    {
      onSuccess: (res) => {
        let data = res?.data;
        if (data) {
          onClose();
          refreshData(uniq([...stylesWatch, ...category?.styles]));
          form.setFieldValue('styles', []);
          ToastSuccess('Category updated');
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    }
  );

  const resetForm = () => {
    form.resetFields();
    form.setFieldValue('style-types', [styleTypeOptions[0]?.value]);
    // form.setFieldValue('styles', []);
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (styleTypesWatch.length > 0) {
      const params = {
        styleTypes: [...styleTypesWatch],
        projectId: category?.project,
        segmentId: category?.segment || '',
      };
      mutationGetStyles.mutate(params);
    } else {
      setStylesOption([]);
    }
  }, [JSON.stringify(styleTypesWatch)]);

  const styleTypeOptions = useMemo(() => {
    return styleTypes.filter((type) =>
      convertCategoryTypeToStyleTyle(category?.type).includes(type.value)
    );
  }, [{ ...category }]);
  useEffect(() => {
    if (styleTypeOptions.length > 0) {
      resetForm();
    }
  }, [JSON.stringify(styleTypeOptions)]);

  const handleOK = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    mutationAddStylesToCategory.mutate(values.styles);
  };

  return (
    <ModalWrapper
      open={open}
      title={null}
      width={841}
      okText={'Add'}
      onCancel={onClose}
      closable={false}
      onOk={handleOK}>
      <BodyWrapper>
        <Form form={form} onFinish={onFinish} requiredMark={false}>
          <FormItem
            label="Style type"
            name="style-types"
            colon={false}
            labelCol={{ span: 5 }}
            labelAlign={'left'}
            rules={[{ required: true, message: 'Please select types!', type: 'array' }]}>
            <Select
              suffixIcon={<ArrowDownIcon />}
              size="large"
              mode="multiple"
              options={styleTypeOptions}
            />
          </FormItem>
          <FormItem
            label="AI style name"
            name="styles"
            colon={false}
            labelCol={{ span: 5 }}
            labelAlign={'left'}
            rules={[{ required: true, message: 'Please select styles!', type: 'array' }]}>
            {/* <SelectWrapper> */}
            <Select
              suffixIcon={<ArrowDownIcon />}
              mode="multiple"
              size="large"
              allowClear
              optionFilterProp="label"
              options={stylesOption
                .filter((style: any) => {
                  return !(category?.styles || []).includes(style._id);
                })
                .map((style: any) => ({
                  value: style._id,
                  label: `${style.name} (${
                    styleTypes.find((type) => type.value === style.styleType)?.label
                  })`,
                }))}
            />
          </FormItem>
        </Form>
      </BodyWrapper>
    </ModalWrapper>
  );
}
