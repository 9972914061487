import { styled } from 'styled-components';

export const ShowImageUploadWrapper = styled.div`
  border: 1px dashed #aaaaaa;
  border-radius: 4px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131317;
  span {
    font-weight: 600;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
