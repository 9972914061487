import { styled } from 'styled-components';

export const UploadImageWrapper = styled.div`
  .btn-upload {
    border: none;
    width: 129px;
    height: 36px;
    background: #f37f13;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon-upload {
      margin-right: 5px;
      svg {
        font-size: 20px;
      }
    }
    &:hover {
      color: white;
      border: none;
    }
  }
`;
