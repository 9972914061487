import { ContentWrapper, DefaultLayoutWrapper, HeaderWrapper } from "./style";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect } from 'react';

export default function DefaultLayout() {

    return (
        <DefaultLayoutWrapper>
           <Sidebar />
        <ContentWrapper>
            <Header />
            <Outlet />
           </ContentWrapper>
        </DefaultLayoutWrapper>
    )
} 