import httpService from './../config/http.service';
import { APIs, VERSION } from '../utils/api';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getListSegment = async (param: any) => {
  const query = '?' + new URLSearchParams(param).toString();
  const res = await httpService(authUrl).get(APIs.LIST_SEGMENT + query);
  return res?.data;
};

export const createSegment = async (payload: any) => {
  const res = await httpService(authUrl).post(APIs.LIST_SEGMENT, payload);
  return res?.data;
};

export const updateSegmentApi = async (segmentId: string, payload: any) => {
  const res = await httpService(authUrl).put(APIs.LIST_SEGMENT + `/${segmentId}`, payload);
  return res?.data;
};

export const deleteSegmentApi = async (segmentId: string) => {
  const res = await httpService(authUrl).delete(APIs.LIST_SEGMENT + `/${segmentId}`);
  return res?.data;
};

export const getAllSegmentApi = async (projectId: any) => {
  const res = await httpService(authUrl).get(APIs.LIST_SEGMENT_ALL + `?projectId=${projectId}`);
  return res?.data;
};
export const getDetailSegment = async (id: string) => {
  const res = await httpService(authUrl).get(APIs.DETAIL_SEGMENT(id));
  return res?.data;
}
