export enum CategoryType {
  'imageToImage' = 'imageToImage',
  'textToImage' = 'textToImage',
  'avatar' = 'avatar',
  'aiFashion' = 'aiFashion',
  'inspirations' = 'inspirations',
  'realistic' = 'realistic',
  'notiReminder' = 'notiReminder',
}
export enum SubscriptionType {
  'free' = 'free',
  'ad' = 'ad',
  'premium' = 'premium',
  'adOrPremium' = 'adOrPremium',
}

export enum CategoryProperty {
  'model' = 'model',
  'foryou' = 'foryou',
  'male' = 'male',
  'female' = 'female',
  'banner' = 'banner',
}

export const propertyOption = [
  { key: 'Default', value: '', label: 'default' },
  { key: CategoryProperty.model, value: CategoryProperty.model, label: CategoryProperty.model },
  { key: CategoryProperty.foryou, value: CategoryProperty.foryou, label: CategoryProperty.foryou },
  { key: CategoryProperty.male, value: CategoryProperty.male, label: CategoryProperty.male },
  { key: CategoryProperty.female, value: CategoryProperty.female, label: CategoryProperty.female },
  { key: CategoryProperty.banner, value: CategoryProperty.banner, label: CategoryProperty.banner },
];

export const categoryNameMaxLength = 150;
export const DEFAULT_CATEGORY_TYPE = CategoryType.imageToImage;
export const categoryTypeOptions = [
  {
    label: 'imageToImage',
    value: CategoryType.imageToImage,
  },
  {
    label: 'textToImage',
    value: CategoryType.textToImage,
  },
  {
    label: 'avatar',
    value: CategoryType.avatar,
  },
  {
    label: 'aiFashion',
    value: CategoryType.aiFashion,
  },
  {
    label: 'inspirations',
    value: CategoryType.inspirations,
  },
  {
    label: 'realistic',
    value: CategoryType.realistic,
  },
  {
    label: 'notiReminder',
    value: CategoryType.notiReminder,
  },
];
export const SubOptions = [
  {
    label: 'Free',
    value: SubscriptionType.free,
  },
  {
    label: 'Ad',
    value: SubscriptionType.ad,
  },
  {
    label: 'Premium',
    value: SubscriptionType.premium,
  },
  {
    label: 'Ad or Premium',
    value: SubscriptionType.adOrPremium,
  },
];
