import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useForm } from 'antd/es/form/Form';
import { ModalEditStyle } from './style';
import UploadImage from '../UploadImage';
import ShowImageUpload from '../ShowImageUpload';
import { getPresignUrl, uploadImageApi } from '../../services/upload';
import { updateStyleInProject } from '../../services/detailProjectService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';

const ModalEditStyleProject = (props: any) => {
  const { open, setOpenEdit, setStyleEdit, styleEdit, projectId } = props;

  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (styleEdit?.thumbnailApp) {
      setImageUrl(styleEdit?.thumbnailApp);
    }

    if (styleEdit?.nameApp) {
      form.setFieldsValue({
        nameApp: styleEdit.nameApp,
      });
    }
  }, [styleEdit]);

  const [file, setFile] = useState<any>();

  const [imageUrl, setImageUrl] = useState<string>('');

  const [form] = useForm();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const body: any = {
        id: projectId,
        styleId: styleEdit._id,
        nameApp: values.nameApp,
      };
      if (file) {
        const res = await getPresignUrl({ filename: file.name });

        const presign = res.data?.data;

        if (presign) {
          await uploadImageApi(presign.thumbnailPresign, file);

          body.thumbnailApp = presign.filepath;
        }
      }

      updateStyleInProject(body)
        .then((res) => {
          handleCancel();
          queryClient.invalidateQueries({
            queryKey: ['detail-project'],
          });
          ToastSuccess(toastMessage.success);
        })
        .catch((error: any) => {
          handleCancel();
          if (error.response?.data) {
            ToastError(error.response?.data?.error?.devMessage);
          } else {
            ToastError(toastMessage.error);
          }
        });
    } catch (err) {
      ToastError(toastMessage.error);
    }
  };

  const handleCancel = () => {
    setOpenEdit(false);
    form.resetFields();
    setStyleEdit(null);
    setImageUrl('');
    setLoading(false);
    setFile(null);
  };

  return (
    <ModalEditStyle
      open={open}
      width={841}
      closable={false}
      footer={null}
      centered
      onCancel={handleCancel}>
      <Form
        requiredMark={false}
        colon={false}
        name="editStyleProject"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={onFinish}
        form={form}
        autoComplete="off">
        <Form.Item
          label="AI style name on App"
          name="nameApp"
          rules={[
            {
              required: true,
              message: 'Please input name of style!',
              type: 'string',
            },
            {
              max: 30,
              message: 'The name of the style cannot be longer than 30 characters!',
            },
          ]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Thumbnail on App">
          <UploadImage setFile={setFile} setImageUrl={setImageUrl} form={form} />
        </Form.Item>
        <ShowImageUpload imageUrl={imageUrl} />
        <div className="footer">
          <Button className="btn-cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="btn-add" onClick={() => form.submit()} loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </ModalEditStyle>
  );
};

export default ModalEditStyleProject;
