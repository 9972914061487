import { styled } from 'styled-components';
import { Collapse } from 'antd';

export const SidebarWrapper = styled.div`
    position: sticky;
    top: 0;
    width: 270px;
    height: 100vh;
    background: #09173B;
    color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding: 0px 15px;
    .logo{
      display: flex;
      text-align: center;
      margin-bottom: 50px;
      gap: 12px;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
    }
    .ant-collapse-expand-icon {
      display: none !important;
    }
    .ant-collapse-content-box {
      background: #09173B !important;
      padding: 0 16px !important;
    }
    .ant-collapse-content {
      border-top: unset !important;
    }
`;

export const CollapseWrapper = styled(Collapse)`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    color: white;
  }
  &.ant-collapse {
    border: none !important;
  }
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 0px !important;
  }
`;

export const PanelWrapper = styled(Collapse.Panel)<{ header: any }>`
  .ant-collapse-content {
    border: none;
    .ant-collapse-content-box {
      padding: 0px;
      padding-block: 0px !important;
    }
  }
  .title-prompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      line-height: 120%;
    }
    img {
      transition: 0.2s;
      position: relative;
      left: 3px;
    }
    .rotate-180 {
      transform: rotate(180deg);
    }
  }
  .setting-slider-panels {
    margin-top: 20px;
  }
  .seed {
    margin-top: 20px;
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      border: none;
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
      background: var(--bg-input-form, #23202c) !important;
      color: white;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.15px;
      padding: 12px;
      &::placeholder {
        color: #9b9c9e;
      }
    }
  }
`;

export const FrameHeader = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background: ${props => props.active ? '#1C2A50' : ''};
  border-radius: 8px;
`;

export const FrameTitle = styled.div`
  display: flex;
  flex: 1;
  text-align: start;
  margin-left: 10px;
  color: var(--saas-vision-lab-white, #FFF);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const FrameIcRow = styled.img<{active: boolean}>`
  display: flex;
  animation: ${props => props.active ? 'rotationOpen 0.5s' : 'rotationClose 0.5s'};
  animation-fill-mode: forwards;
  @keyframes rotationOpen {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
  @keyframes rotationClose {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

export const FrameSub = styled.div<{active: boolean}>`
  display: flex;
  margin-left: 20px;
  padding: 10px 0 10px 10px;
  border-left: #3A4562 1px solid;
  cursor: pointer;
  color: ${props => props.active ? '#F37F13' : 'white'};
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const FrameSubs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;