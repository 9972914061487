import { Button, Checkbox, Col, Form, Input, Row, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CheckboxWrapper,
  FormWrapper,
  FrameAdd,
  FrameCancel,
  FrameIcClose,
  FrameOptionItem,
  FrameTitle,
  FrameTools,
  Label,
  ModalCreate,
  Space,
  TxtTitle,
} from './style';
import { useSelector } from 'react-redux';
import IcClose from '../../../../assets/icons/ic_close.svg';
import { IProps } from './interface';
import { RULES } from './validate';
import { InputCustom } from '../../../../components/InputCustom';
import { SelectCustom } from '../../../../components/SelectCustom';
import { Style, ImageSize, BaseModel } from './constant';
const { Option } = Select;

export const AddSegment = (props: IProps) => {
  const { open, closeModal, addNewSegment, updateSegment, segmentEdit } = props;
  const option = ImageSize.map((e: any) => {
    return {
      label: e.value,
      value: e.value,
    };
  });

  const optionBaseModel = BaseModel.map((e: any) => {
    return {
      label: e.value,
      value: e.value,
    };
  });

  const defaultImageSize = ImageSize.find((e: any) => e.type === 'default')?.value;
  const defaultBaseModel = BaseModel.find((e: any) => e.type === 'default')?.value;

  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (segmentEdit) {
      console.log('🚀 ~ file: index.tsx:49 ~ useEffect ~ segmentEdit:', segmentEdit);

      form?.setFieldsValue({
        name: segmentEdit?.name,
        firebaseValue: segmentEdit?.firebaseValue,
        aiArtImageSize: segmentEdit?.aiArtImageSize,
        aiFashionImageSize: segmentEdit?.aiFashionImageSize,
        aiSuperArtImageSize: segmentEdit?.aiSuperArtImageSize,
        aiArtBaseModel: segmentEdit?.aiArtBaseModel,
        aiFashionBaseModel: segmentEdit?.aiFashionBaseModel,
        aiSuperArtBaseModel: segmentEdit?.aiSuperArtBaseModel,
        shouldCollectImage: !!segmentEdit?.shouldCollectImage,
      });
    } else {
      form.setFieldsValue({
        name: '',
        firebaseValue: '',
        aiArtImageSize: defaultImageSize,
        aiFashionImageSize: defaultImageSize,
        aiSuperArtImageSize: defaultImageSize,
        aiArtBaseModel: defaultBaseModel,
        aiFashionBaseModel: defaultBaseModel,
        aiSuperArtBaseModel: defaultBaseModel,
        shouldCollectImage: false,
      });
    }
  }, [segmentEdit, open]);

  const onFinish = async (values: any) => {
    const { aiArtBaseModel, aiFashionBaseModel, aiSuperArtBaseModel } = values;
    const payload = {
      name: values?.name.trim(),
      firebaseValue: values?.firebaseValue.trim(),
      aiArtImageSize: values?.aiArtImageSize,
      aiFashionImageSize: values?.aiFashionImageSize,
      aiSuperArtImageSize: values?.aiSuperArtImageSize,
      aiArtBaseModel,
      aiFashionBaseModel,
      aiSuperArtBaseModel,
      shouldCollectImage: !!values?.shouldCollectImage,
    };
    if (segmentEdit) {
      updateSegment(payload);
    } else {
      addNewSegment(payload);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
    setLoading(false);
  };

  const handleCreateStyle = () => {
    form.submit();
  };

  return (
    <>
      <ModalCreate
        width={700}
        footer={null}
        centered
        open={open}
        onCancel={handleCancel}
        closeIcon={null}>
        {contextHolder}
        <FrameTitle>
          <TxtTitle>{segmentEdit ? 'Edit User Segment' : 'Add User Segment'}</TxtTitle>
          <FrameIcClose src={IcClose} onClick={handleCancel} />
        </FrameTitle>
        <Form
          requiredMark={false}
          layout="vertical"
          colon={false}
          form={form}
          name="createStyle"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          initialValues={{
            name: segmentEdit?.name || '',
            firebaseValue: segmentEdit?.firebaseValue || '',
            aiArtImageSize: segmentEdit?.aiArtImageSize || defaultImageSize,
            aiFashionImageSize: segmentEdit?.aiFashionImageSize || defaultImageSize,
          }}
          autoComplete="off">
          <FormWrapper>
            <InputCustom
              key={'User segment'}
              label="User segment"
              name="name"
              rules={RULES.NAME}
              placeholder={'user segment'}
              maxLength={150}
              showCount={true}
            />

            <InputCustom
              key={'Value'}
              label="Value"
              name="firebaseValue"
              rules={RULES.VALUE}
              placeholder={'value'}
              maxLength={150}
              showCount={true}
            />

            <CheckboxWrapper>
              <Label>Collect image</Label>
              <Form.Item name="shouldCollectImage" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </CheckboxWrapper>

            <Label>Image Size</Label>
            <SelectCustom
              key={'AI Art'}
              label="AI Art"
              name="aiArtImageSize"
              placeholder="Select image size..."
              rules={RULES.ART}
              options={option}
              horizontal={true}
              labelCol={{ span: 4 }}
            />
            <SelectCustom
              key={'AI Fashion'}
              label="AI Fashion"
              name="aiFashionImageSize"
              placeholder="Select image size..."
              rules={RULES.FASHION}
              options={option}
              horizontal={true}
              labelCol={{ span: 4 }}
            />
            <SelectCustom
              key={'AI Super Art'}
              label="AI Super Art"
              name="aiSuperArtImageSize"
              placeholder="Select image size..."
              rules={RULES.SUPER_ART}
              options={option}
              horizontal={true}
              labelCol={{ span: 4 }}
            />

            <Label>Base Model</Label>
            <SelectCustom
              key={'AI Art'}
              label="AI Art"
              name="aiArtBaseModel"
              placeholder="Select base model..."
              rules={RULES.ART_MODEL}
              options={optionBaseModel}
              horizontal={true}
              labelCol={{ span: 4 }}
            />
            <SelectCustom
              key={'AI Fashion'}
              label="AI Fashion"
              name="aiFashionBaseModel"
              placeholder="Select base model..."
              rules={RULES.FASHION_MODEL}
              options={optionBaseModel}
              horizontal={true}
              labelCol={{ span: 4 }}
            />
            <SelectCustom
              key={'AI Super Art'}
              label="AI Super Art"
              name="aiSuperArtBaseModel"
              placeholder="Select base model..."
              rules={RULES.SUPER_ART_MODEL}
              options={optionBaseModel}
              horizontal={true}
              labelCol={{ span: 4 }}
            />
          </FormWrapper>
        </Form>
        <FrameTools>
          <FrameCancel onClick={handleCancel}>Cancel</FrameCancel>
          <FrameAdd onClick={handleCreateStyle}>
            {segmentEdit ? 'Update User segment' : 'Add User segment'}
          </FrameAdd>
        </FrameTools>
      </ModalCreate>
    </>
  );
};
