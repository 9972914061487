/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { routerAdmin, routerUser } from './routes';
import tokenAuthService from './utils/tokenAuthService';
import authServices from './services/authService';
import { setUserInfo } from './redux/slices/authSlice';
import { ToastError } from './components/toast-message/ToastMessage';
import { ROLES } from './constants';
import { RootState } from './redux/store';

export const App = () => {
  const dispatch = useDispatch();
  const authData = tokenAuthService.getLocalAuthUser() || {};
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [role, setRole] = useState(ROLES.ADMIN);

  useEffect(() => {
    if (authData?.token) {
      mutationGetProfile.mutate();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.role) {
      setRole(userInfo?.role);
    }
  }, [userInfo?.role]);

  const mutationGetProfile = useMutation(() => authServices.getProfile(), {
    onSuccess: (res) => {
      let data = res?.data?.data;
      if (data) {
        data = { ...data, id: data._id };
        delete data._id;
        dispatch(setUserInfo(data));
        setRole(data?.role || ROLES.USER);
      }
    },
    onError: (error: any) => {
      tokenAuthService.removeAuthUser();
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const router = useMemo(() => {
    return role === ROLES.USER ? routerUser : routerAdmin;
  }, [role]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer closeButton={false} />
    </>
  );
};
