import httpService from './../config/http.service';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getDetailProject = async (id: string, params?: any) => {
  const res = await httpService(authUrl).get(`/styles/projects/${id}`, { params });
  return res?.data.data;
};

export const addStyleToProject = async (body: any) => {
  const res = await httpService(authUrl).post(`/v3/styles`, body);
  return res?.data.data;
};

export const deleteStyleInProject = async (body: any) => {
  const res = await httpService(authUrl).put(`/v3/projects/delete-style`, body);
  return res?.data.data;
};

export const updateStyleInProject = (body: any) => {
  return httpService(authUrl).put(`/v3/projects/update-style`, body);
};

export const editPriorityStyle = async (payload: any) => {
  const res = await httpService(authUrl).put(`/v3/styles/update-priority`, payload);
  return res?.data.data;
};
