import styled from "styled-components";
import {Form} from 'antd'
export const FormItemHorizontal = styled(Form.Item)`
  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  
  .ant-form-item-label {
    white-space: nowrap;
    text-align: start;
    padding: 0;
  }

  .ant-form-item-control {
    flex: 1 1 0;
    min-width: 0;
  }
  
  .ant-form-item-label > label {
    height: 45px;
    color: #000;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px; /* 137.5% */
    &::after {
      display: block;
      content: ':';
      position: relative;
      margin-block: 0;
      margin-inline-start: 2px;
      margin-inline-end: 8px;
    }
  }
`
