import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .ant-table-wrapper {
    margin-bottom: 20px;
  }
`;

export const TxtTitle = styled.div`
  width: 100%;
  color: var(--scale-bg-blue-bg-blue-01, #060F27);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  text-transform: uppercase;
  border-bottom: 1px solid var(--scale-light-scale-line, #EAEAEA);
  padding: 16px 0;
`;

export const BtnAddSegment = styled.div`
  display: flex;
  width: fit-content;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--primary-primary, #F37F13);
  color: var(--scale-light-scale-white, #FFF);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 16px 0 24px;
  cursor: pointer;
`;
export const FrameImageSize = styled.div`
  display: flex;
  width: 136px;
  //height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  flex-shrink: 0;
  color: var(--scale-gray-scale-high-emp, #131317);

  /* Caption 1/Regular */
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`