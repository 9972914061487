import { styled } from 'styled-components';
import { Select, Tabs } from 'antd';

export const DetailProjectWrapper = styled.div`
  background-color: #f7f7f7;
  flex: 1;
  padding: 24px;
  .content {
    position: relative;
    height: 100%;
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #131317;
      margin-bottom: 10px;
    }
    .list-style {
      width: 100%;
      overflow: hidden;
      border-top: 1px solid #eaeaea;
      padding: 15px 0px;
      .box-search {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        .input-search {
          padding: 14px;
          width: 400px;
          border-radius: 4px;
          border: 1px solid #eaeaea;
          input {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-left: 8px;
            &::placeholder {
              color: #8c8c92;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
        .group-btn {
          display: flex;
          gap: 15px;
          .edit-priority {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #f37f13;
            height: 51px;
            width: 200px;
            border: 1px solid #f37f13;
            border-radius: 8px;
          }
          .ant-select-selector {
            display: flex;
            height: 51px !important;
            justify-content: center !important;
            align-items: center !important;
            border-radius: 8px;
            border: 1px solid #f37f13 !important;
            min-width: 200px !important;
          }
          .ant-select-selection-item {
            padding: 12px 24px !important;
          }
          .ant-select-selection-item {
            color: #f37f13;
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
          .ant-select-arrow {
            display: flex !important;
            color: #f37f13;
            margin-top: 4px;
          }
        }
        .add-ai {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f37f13;
          border-radius: 8px;
          padding: 13px 24px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: white;
          gap: 18px;
          .plus {
            width: 20px;
            height: 20px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #f37f13;
            border-radius: 50%;
            font-size: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
    .truncate-text {
      max-width: 150px; /* Set your desired max-width */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const FrameHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 17px;
  .ant-select {
    width: 220px !important;
  }
  .ant-select-selection-item {
    //width: fit-content !important;
    padding: 12px 24px !important;
  }
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #f37f13 !important;
  }
  .ant-select-arrow {
    display: flex !important;
    color: #f37f13;
  }
  .ant-select-single {
    height: 48px !important;
  }
  .ant-select-selection-item {
    color: #f37f13;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
`;

export const BtnSegment = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  padding: 12px 24px;
  border-radius: 8px;
  border: #f37f13 1px solid;
  color: #f37f13;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  gap: 16px;
`;

export const TabCustom = styled(Tabs)`
  .ant-tabs-tab-btn {
    color: #8c8c92;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-transform: uppercase;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #060f27 !important;
    }
  }
  .ant-tabs-ink-bar {
    border-radius: 4px;
    background: #f37f13 !important;
  }
`;
