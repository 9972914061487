import { Modal } from 'antd';
import { styled } from 'styled-components';

export const ArrangeStyleModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: 24px 16px;
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #333333;
    }
    .title-sort {
      display: flex;
      justify-content: space-between;
      padding-left: 36px;
      padding-right: 36px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 17px;
      margin-top: 49px;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 50px;
      .btn-cancel {
        width: 140px;
        height: 46px;
        background: #f7f7f7;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #131317;
        border: none;
      }
      .btn-add {
        background: #f37f13;
        border-radius: 8px;
        width: 140px;
        height: 46px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        border: none;
      }
    }
  }
`;

export const SortListWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const SortItemWrapper = styled.div`
  cursor: pointer;
  .icon-drag {
    width: 20px;
    height: 20px;
  }
  position: relative;
  z-index: 10000;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .row {
    align-items: center;
    padding-right: 80px;
    flex: 1;
    margin-left: 16px;
    display: flex;
    justify-content: space-between;
    .icon-style {
      width: 40px;
      height: 40px;
    }
  }
`;
