import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  .ant-form-item {
    width: 100%;
  }
`;

export const FrameUpload = styled.div`
  display: flex;
  width: fit-content;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #F37F13;
  color: #FFF;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  position: absolute;
  left: 160px;
  top: -5px
`;

export const FrameEmpty = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FrameImg = styled.img`
  display: flex;
  height: 200px;
  width: 200px;
  object-fit: cover;
`;

export const SpanBold = styled.span`
  color: var(--scale-gray-scale-high-emp, #131317);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const SpanMid = styled.span`
  color: #2B2B30;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-line;
`;