import { StyleType } from '../constants';
import { CategoryType } from '../pages/DetailProject/component/Category/constant';

export const pageAccessedByReload = () => {
  if (window.performance) {
    if (performance.navigation.type == 1) return true;
    return false;
  }
  return false;
};

export const convertConfig = (style: string, data: any) => {
  return {
    alpha: data?.alpha,
    guidanceScale: data?.guidance_scale,
    negativePrompt: data?.negative_prompt,
    numInferenceSteps: data?.num_inference_steps,
    positivePrompt: data?.positive_prompt,
    strength: data?.strength,
    style: style,
  };
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const ImageType = ['png', 'jpg'];
export const FileFormat = ['png', 'jpg', 'mp4'];

export const checkIsImage = (name: string) => {
  if (!name) return false;
  const arr = name.split('.');
  return ImageType.includes(arr[arr.length - 1].toLowerCase());
};

export const formatDateToYYYYMMDDHHMM = (datetime: any) => {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const checkOverPage = (page: number, limit: number, totalItem: number) => {
  if ((page - 1) * limit + 1 >= totalItem) {
    return page - 1;
  }
  return page;
};

export const subtractArrays = (arr1: any, arr2: any) => {
  return arr1.reduce((result: any, item1: any) => {
    if (!arr2.some((item2: any) => item2.value === item1.value)) {
      result.push(item1);
    }
    return result;
  }, []);
};

export const convertStyleTyleToCategoryType = (styleType: string) => {
  let categoryTypes: any = [];
  switch (styleType) {
    case StyleType.ART:
      categoryTypes = [
        CategoryType['textToImage'],
        CategoryType['imageToImage'],
        CategoryType['aiFashion'],
      ];
      break;
    case StyleType.AI_FASHION:
      categoryTypes = [CategoryType['imageToImage'], CategoryType['aiFashion']];
      break;
    case StyleType.AVATAR:
      categoryTypes = [CategoryType['avatar']];
      break;
    default:
      break;
  }
  return categoryTypes;
};

export const convertCategoryTypeToStyleTyle = (categoryType: string) => {
  let styleTypes: string[] = [];
  switch (categoryType) {
    case CategoryType['textToImage']:
      styleTypes = [StyleType['ART'], StyleType['SUPERART']];
      break;
    case CategoryType['imageToImage']:
      styleTypes = [StyleType['ART'], StyleType['AI_FASHION'], StyleType['SUPERART']];
      break;
    case CategoryType['inspirations']:
      styleTypes = [StyleType['ART'], StyleType['AI_FASHION'], StyleType['SUPERART']];
      break;
    case CategoryType['realistic']:
      styleTypes = [StyleType['ART'], StyleType['AI_FASHION'], StyleType['SUPERART']];
      break;
    case CategoryType['aiFashion']:
      styleTypes = [StyleType['AI_FASHION'], StyleType['SUPERART']];
      break;
    case CategoryType['avatar']:
      styleTypes = [StyleType['AVATAR'], StyleType['SUPERART']];
      break;
    case CategoryType['notiReminder']:
      styleTypes = [StyleType['ART'], StyleType['SUPERART'], StyleType['AI_FASHION']];
      break;
    default:
      break;
  }
  return styleTypes;
};

export const convertFileUpload = (fileName: string) => {
  if (!fileName) return `${new Date().valueOf()}-image.png`;
  return `${new Date().valueOf()}-${fileName}`;
};
