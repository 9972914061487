import React, { useEffect, useMemo, useState } from 'react';
import { IProps } from './interface';
import { FrameEmpty, FrameImg, FrameUpload, SpanBold, SpanMid, Wrapper } from './styles';
import { Form } from 'antd';
import { ToastError } from '../../../toast-message/ToastMessage';
import { checkIsImage, FileFormat } from '../../../../utils/helper';
import IcUpload from './../../../../assets/icons/ic_upload_white.svg';

export const Thumbnail = (props: IProps) => {
  const { form } = props;
  const [thumbnail, setThumbnail] = useState<any>(null);

  const key = form.getFieldValue('key');

  useEffect(() => {
    setThumbnail(key);
  }, [key])

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (file && file.size > maxSizeInBytes) {
      ToastError('Max file size is 10mb');
      e.target.value = null;
      return;
    }
    const arr = file.name.split('.');
    if (!FileFormat.includes(arr[arr.length - 1].toLowerCase())) {
      ToastError('You can upload jpg, png, mp4 file');
      e.target.value = null;
      return;
    }
    const isImage = checkIsImage(file.name);
    const data = {
      thumbnail: file,
      isImage,
    };
    form.setFieldValue('key', data);
    form?.validateFields(['key']);
    setThumbnail(data);
    e.target.value = null;
  };

  const uploadFile = () => {
    document.getElementById('fileThumbnail')?.click();
  };

  const isFile = useMemo(() => {
    return thumbnail?.isImage === true || thumbnail?.isImage == false
  }, [thumbnail])

  const isImage = useMemo(() => {
    return isFile ? thumbnail?.isImage : checkIsImage(thumbnail);
  }, [thumbnail]);

  const link = useMemo(() => {
    return isFile ? URL.createObjectURL(thumbnail.thumbnail) : thumbnail;
  }, [thumbnail])

  return (
    <Wrapper>
      <input
        type="file"
        id="fileThumbnail"
        accept=".png, .jpg, .mp4"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Form.Item
        label="Thumbnail"
        name="key"
        rules={[
          {
            required: true,
            message: 'Please upload thumbnail',
          },
        ]}
      >
      </Form.Item>
      <FrameUpload onClick={uploadFile}>
        <img src={IcUpload}/>
        Upload
      </FrameUpload>
      {
        !thumbnail ?
          <FrameEmpty>
            <span>
              <SpanBold>PNG, JPG, MP4 .</SpanBold>
              <SpanMid>{'\nwith max size of '}<SpanBold>10MB.</SpanBold></SpanMid>
            </span>
          </FrameEmpty> :
          isImage ?
            <FrameImg
              alt="thumbnail"
              src={link}
            /> :
            <video width="200px" height="200px" controls key={link}>
              <source src={link} type="video/mp4"/>
            </video>
      }
    </Wrapper>
  );
};