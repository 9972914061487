import React, { useEffect, useMemo, useState } from 'react';
import {
  FrameHeaderUpload,
  FrameImg,
  FrameListThumbnailApp,
  FrameThumbnail,
  ImgIcClose,
  ImgIcUpload,
  TxtTitleThumb,
} from './styles';
import IcUploadBlack from '../../../../assets/icons/ic_upload_black.svg';
import IcCloseBlack from '../../../../assets/icons/ic_close_back.svg';
import { IProps, IThumbnail, ThumbnailOption } from './interface';
import { Form, Select } from 'antd';
import { useMutation } from 'react-query';
import { createThumbnailType, getThumbnailType } from '../../../../services/listProjectService';
import { ToastError } from '../../../toast-message/ToastMessage';
import { checkIsImage, FileFormat, ImageType } from '../../../../utils/helper';
import { useParams } from 'react-router-dom';
import { useWatch } from 'antd/lib/form/Form';

export const ThumbnailApp = (props: IProps) => {
  const { form, styleEdit, thumbnailTypeOption, getDataThumbnailType } = props;
  const [thumbnailAdd, setThumbnailAdd] = useState({
    value: '',
    index: 0,
  });

  const { projectId, id } = useParams();

  console.log('projectId', projectId);

  useEffect(() => {
    if (thumbnailAdd.value) {
      let existOption = thumbnailTypeOption.findIndex(
        (item: any) => item?.value === thumbnailAdd.value
      );
      if (existOption !== -1) {
        handleChange(thumbnailAdd.value, thumbnailAdd.index);
      }
    }
  }, [thumbnailAdd?.value, thumbnailTypeOption]);

  const thumbnailApp = useWatch('thumbnailApp');

  const handleCreateThumbnailType = useMutation((payload: any) => createThumbnailType(payload), {
    onSuccess: (res: any) => {
      if (res?.data) {
        getDataThumbnailType();
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const handleButtonClick = () => {
    document.getElementById('fileInput')?.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (file && file.size > maxSizeInBytes) {
      ToastError('Max file size is 10mb');
      e.target.value = null;
      return;
    }
    const arr = file.name.split('.');
    if (!FileFormat.includes(arr[arr.length - 1].toLowerCase())) {
      ToastError('You can upload jpg, png, mp4 file');
      e.target.value = null;
      return;
    }
    const isImage = checkIsImage(file.name);
    form.setFieldsValue({
      thumbnailApp: [
        ...form.getFieldValue('thumbnailApp'),
        {
          thumbnail: file,
          thumbnail_type: null,
          isImage,
        },
      ],
    });
    e.target.value = null;
  };

  const handleChange = (value: string, index: number) => {
    let data = [...form.getFieldValue('thumbnailApp')];
    data[index] = { ...data[index], thumbnail_type: value };
    form.setFieldValue('thumbnailApp', data);
    form.validateFields([['thumbnailApp', index, 'thumbnail_type']]);
    setThumbnailAdd({
      value: '',
      index: 0,
    });
  };

  const handleEnter = (e: any, index: number) => {
    if (e.key === 'Enter' && e.target.value) {
      const exist = thumbnailTypeOption.findIndex((item: ThumbnailOption) =>
        item.value.includes(e.target.value)
      );
      if (exist === -1) {
        handleCreateThumbnailType.mutate({
          projectId: projectId || id,
          value: e.target.value,
        });
        setThumbnailAdd({
          value: e.target.value,
          index,
        });
      }
    }
  };

  return (
    <FrameListThumbnailApp>
      <FrameHeaderUpload>
        <TxtTitleThumb>{'List thumbnail for app'}</TxtTitleThumb>
        <ImgIcUpload src={IcUploadBlack} onClick={handleButtonClick} />
        <input
          type="file"
          id="fileInput"
          accept=".png, .jpg, .mp4"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </FrameHeaderUpload>
      <Form.List name="thumbnailApp">
        {(fields, { add, remove }) => (
          <div style={{ marginTop: fields?.length ? '37px' : '0' }}>
            {fields.map(({ key, name, fieldKey, ...restField }: any, index: number) => {
              const thumbnail = form.getFieldValue(['thumbnailApp', index]);
              const isFile = thumbnail?.isImage === true || thumbnail?.isImage == false;
              const isImage = isFile ? thumbnail?.isImage : checkIsImage(thumbnail.thumbnail);
              return (
                <FrameThumbnail>
                  {isImage ? (
                    <FrameImg
                      alt="thumbnail"
                      src={isFile ? URL.createObjectURL(thumbnail.thumbnail) : thumbnail.thumbnail}
                    />
                  ) : (
                    <video width="auto" height="200px" controls>
                      <source
                        src={
                          isFile ? URL.createObjectURL(thumbnail.thumbnail) : thumbnail.thumbnail
                        }
                      />
                    </video>
                  )}
                  <Form.Item
                    key={key}
                    {...restField}
                    name={[name, 'thumbnail_type']}
                    fieldKey={[fieldKey, 'thumbnail_type']}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) return Promise.reject('This field cannot be empty');
                          let array = [...thumbnailApp];
                          array.splice(index, 1);
                          const iDuplicate = array.findIndex(
                            (item: any) => item?.thumbnail_type === value
                          );
                          if (iDuplicate !== -1) return Promise.reject('Not use value duplicate');
                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Thumbnail type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label || '').toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value: string) => handleChange(value, index)}
                      onKeyDown={(e: any) => handleEnter(e, index)}
                      options={thumbnailTypeOption}
                      value={thumbnail?.thumbnail_type}
                    />
                  </Form.Item>
                  <ImgIcClose src={IcCloseBlack} onClick={() => remove(index)} />
                </FrameThumbnail>
              );
            })}
            <Form.Item></Form.Item>
          </div>
        )}
      </Form.List>
    </FrameListThumbnailApp>
  );
};
