import { SortableElement } from 'react-sortable-hoc';
import { SortItemWrapper } from './style';
import IconDrag from '../../assets/images/icon-drag.svg';
import { checkIsImage } from '../../utils/helper';
import React from 'react';

const SortableItem = (props) => {
  const isImage = checkIsImage(props.value.key);
  return <SortItemWrapper>
    <img src={IconDrag} alt="" className="icon-drag"/>
    <div className="row">
      <span>{props.value.name}</span>
      {
        isImage ?
          <img className="icon-style" src={props.value.key} alt=""/> :
          <video width="40px" height="40px" key={props.value.key}>
            <source src={props.value.key}/>
          </video>
      }

    </div>
  </SortItemWrapper>;
};

export default SortableElement(SortableItem);
