import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { arrayMoveImmutable } from 'array-move';
import { Button } from 'antd';
import { ModalEditPriorityWrapper } from './style';
import SortableList from './sortList';
import { editPriorityStyle } from '../../services/detailProjectService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';

const ModalEditPriority = (props: any) => {
  const { detail, open, setOpen, listStyle, projectId, params, setParams, setNameSearch, reloadAllStyle } = props;
  const queryClient = useQueryClient();
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setItems(listStyle);
  }, [listStyle, open]);

  const mutationEditPriority = useMutation(
    (payload: any) => editPriorityStyle(payload), {
    onSuccess: (data) => {
      onCancel();
      setParams({ ...params, page: 1, name: '' });
      reloadAllStyle();
      queryClient.invalidateQueries({
        queryKey: ['detail-project'],
      });
      ToastSuccess(toastMessage.success);
    },
    onError: () => {
      ToastError(toastMessage.error);
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
  };

  const onCancel = () => {
    setOpen(false);
    setLoading(false);
  };

  const onUpdateSort = () => {
    const ids = items.map((item: any) => item?._id);
    mutationEditPriority.mutate({
      styles: ids
    });
  }


  return (
    <ModalEditPriorityWrapper
      open={open}
      width={476}
      closable={false}
      footer={null}
      centered
      onCancel={onCancel}>
      <div className="title">Edit Priority</div>
      <div className="title-sort">
        <span>Style name on app</span>
        <span>Thumbnail on app</span>
      </div>
      <SortableList items={items} onSortEnd={onSortEnd} />
      <div className="footer">
        <Button className="btn-cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="btn-add" onClick={onUpdateSort}>
          Update
        </Button>
      </div>
    </ModalEditPriorityWrapper>
  );
};

export default ModalEditPriority;
