import httpService from '../config/http.service';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getDetailCategory = async (id: any) => {
  const res = await httpService(authUrl).get(`/category/${id}`);
  return res?.data.data;
};

// export const addStyleToProject = async (body: any) => {
//   const res = await httpService(authUrl).post(`/v3/styles`, body);
//   return res?.data.data;
// };

// export const deleteStyleInProject = async (body: any) => {
//   const res = await httpService(authUrl).put(`/v3/projects/delete-style`, body);
//   return res?.data.data;
// };

// export const updateStyleInProject = (body: any) => {
//   return httpService(authUrl).put(`/v3/projects/update-style`, body);
// };

// export const editPriorityStyle = async (body: any, id: any) => {
//   const res = await httpService(authUrl).put(`/v3/styles/${id}/priority`, body);
//   return res?.data.data;
// };
