import React, { useState, ChangeEvent, useEffect } from 'react';
import { Select, Tag, Input, Button, Form, Tooltip } from 'antd';
import { AddTag } from './style';
import { useForm } from 'antd/es/form/Form';
import IconAdd from '../../assets/icons/icon-add.svg'
import './SelectStyle.css';
import { log } from 'console';
const CustomInputSelect = (props: any) => {
    const {selectedOptions,  setSelectedOptions} = props

    const [form] = useForm();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const onFinish = (values: any) => {
        console.log('value', values);
        
        const { key, value } = values;
        const tag = {
        key: key,
        value: value,
        }
        console.log('tag', tag);
    
        if (tag && selectedOptions ) {
        setSelectedOptions([...selectedOptions, tag]);
        form.resetFields();

    }
    }
    const handleInputConfirm = () => {
      form.submit();
  };

  
  const handleClose = (removedTag: any) => {
    console.log('removeee', removedTag);
    
    setSelectedOptions((prevOptions: any) => prevOptions.filter((tag: any) => {
      console.log("tag", tag);
      
      return tag.key !== removedTag.key && tag.value !== removedTag.value
    }));
    
  };
  console.log(selectedOptions);

  return (
    <>
      <div className="ant-input" style={{ height: 'auto', display :'flex', gap: '4px', flexWrap:'wrap', minHeight: '37px'}}>
        {selectedOptions && selectedOptions.map((tag:any) => (
          <Tag
          closable
          onClose={(e) => {
            e.preventDefault();
            handleClose(tag);
          }}
          >
            {tag.key}: {tag.value}
          </Tag>
        ))}
      </div>
          <Form
            form={form}
            requiredMark={true}
            colon={false}
            onFinish={onFinish}
            autoComplete="off"
          > 
            <AddTag>
                  <Form.Item
                      name="key"
                      rules={[
                          {
                              required: true,
                              message: 'This field cannot be empty',
                              type: 'string',
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim().length === 0) {
                              return Promise.reject('Cannot contain only spaces!');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                  > 
                      <Input
                        style={{ width: '140%' }}
                        type="text"
                        
                          placeholder="Key"
                          maxLength={100}
                          name="key"
                        />
                    </Form.Item> 
                  <Form.Item
                  name="value"
                  rules={[
                    {
                        required: true,
                        message: 'This field cannot be empty',
                        type: 'string',
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject('Cannot contain only spaces!');
                        }
                        return Promise.resolve();
                      },
                    },
                    ]}>
                    <Input
                    style={{ width: '140%' }}
                    type="text"
                    
                          placeholder="Value"
                          maxLength={100}
                          name="value"
                      />
                </Form.Item>  
                  <Button onClick={handleInputConfirm} style={{border: 'none'}}>
                    <img src={IconAdd} alt =""/>
                  </Button>  
            </AddTag>
          </Form> 
    </>
  );
};

export default CustomInputSelect;
