import { Form } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

export const FrameLogo = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  background: #131317;
  align-items: center;
  justify-content: center;
`;

export const FrameLogin = styled.div`
  display: flex;
  flex: 1;
  padding: 37px 50px;
  flex-direction: column;
`;

export const FrameImg = styled.img`
  display: flex;
  height: 50%;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  height: 100vh;
  background: #09173B;
  padding: 0 35%;
  .ant-input-affix-wrapper {
    height: 48px;
    background: #ffffff;
    border-width: 0px;
    border-color: transparent;
    border-radius: 8px;
    padding: 0px 16px;
    input.ant-input {
      background: #fff;
      padding-left: 15px;
      color: black;
    }
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper {
    border-width: 1px;
  }

  .ant-form-item-explain-error {
    margin-top: 5px;
  }
`

export const FrameContent = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ButtonWrapper = styled('button')`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F37F13;
  color: #ffffff;
  font-size: 16px;
  border: unset;
  cursor: pointer;
  margin-top: 50px;
  &:hover {
    opacity: 0.8;
  }
`
export const TextApp = styled('h3')`
  font-size: 30px;
  color: #ffffff;
`

export const FrameCenter = styled.div`
  display: flex;
  flex: 1;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 0 20%;
  text-align: start;
`;

export const TxtTitle = styled.div`
  color: var(--scale-gray-scale-high-emp, #131317);
  font-family: Noto Sans;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 127.273% */
  letter-spacing: 0.88px;
`;

export const TxtName = styled.div`
  color: var(--scale-gray-scale-high-emp, #131317);
  font-family: Noto Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 175% */
  letter-spacing: 0.64px;
`;
