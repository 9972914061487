export const RULES = {
  NAME: [
    {
      required: true,
      message: 'The user segment cannot be empty',
      type: 'string',
    },
    {
      validator: (_: any, value: any) => {
        if (value && value.trim().length === 0) {
          return Promise.reject('Cannot contain only spaces!');
        }
        return Promise.resolve();
      },
    },
  ],
  VALUE: [
    {
      required: true,
      message: 'The value cannot be empty',
      type: 'string',
    },
    {
      validator: (_: any, value: any) => {
        if (value && value.trim().length === 0) {
          return Promise.reject('Cannot contain only spaces!');
        }
        return Promise.resolve();
      },
    },
  ],
  ART: [
    {
      required: true,
      message: 'Please select image size!',
      type: 'string',
    },
  ],
  FASHION: [
    {
      required: true,
      message: 'Please select image size!',
      type: 'string',
    },
  ],
  AVATAR: [
    {
      required: true,
      message: 'Please select image size!',
      type: 'string',
    },
  ],
  SUPER_ART: [
    {
      required: true,
      message: 'Please select image size!',
      type: 'string',
    },
  ],
  ART_MODEL: [
    {
      required: true,
      message: 'Please select base model!',
      type: 'string',
    },
  ],
  FASHION_MODEL: [
    {
      required: true,
      message: 'Please select base model!',
      type: 'string',
    },
  ],
  SUPER_ART_MODEL: [
    {
      required: true,
      message: 'Please select base model!',
      type: 'string',
    },
  ],
};
