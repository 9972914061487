import { SortableElement } from 'react-sortable-hoc';
import { SortItemWrapper } from './style';
import IconDrag from '../../assets/images/icon-drag.svg'
import { checkIsImage } from '../../utils/helper';

const SortableItem = (props) => {
  const thumbnailIsImage = checkIsImage(props.value.thumbnailApp)
  return <SortItemWrapper>
    <img src={IconDrag} alt="" className='icon-drag' />
    <div className='row'>
      <span>{props.value.nameApp}</span>
      {thumbnailIsImage ? <img className='icon-style' src={props.value.thumbnailApp} alt="" />
                : <video width="40px" height="40px" key={props.value.thumbnailApp} controls>
                    <source src={props.value.thumbnailApp} />
                  </video>
      }
    </div>
  </SortItemWrapper>;
};

export default SortableElement(SortableItem);
