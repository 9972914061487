import React from 'react';
import SortableItem from './sortItem';
import { SortableContainer } from 'react-sortable-hoc';
import { SortListWrapper } from './style';

const SortableList = (props) => {
  return (
    <SortListWrapper>
      {props.items?.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </SortListWrapper>
  );
};

export default SortableContainer(SortableList);
