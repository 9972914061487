import styled from 'styled-components';
export const DefaultLayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  * {
    font-family: 'Noto Sans';
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 270px);
  height: 100%;
  overflow: hidden;
`;
export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
`;
