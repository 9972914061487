import { styled } from 'styled-components';

export const FrameListThumbnailApp = styled.div`
  display: flex;
  flex-direction: column;
  .ant-select-selector {
    width: 200px !important;
  }
  .ant-form-item-control-input {
    min-height: 0 !important;
  } 
`;

export const FrameHeaderUpload = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  //margin-bottom: 37px;
`;

export const TxtTitleThumb = styled.div`
  color: var(--scale-gray-scale-high-emp, #131317);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const ImgIcUpload = styled.img`
  display: flex;
  cursor: pointer;
`;

export const FrameThumbnail = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 25px;
  gap: 28px;
  align-items: flex-start;
  .ant-select-arrow {
    display: none !important;
  }
  .ant-form-item-explain-error {
    width: max-content;
  }
`;

export const ImgIcClose = styled.img`
  cursor: pointer;
  margin-top: 5px;
`;

export const FrameImg = styled.img`
  display: flex;
  height: 200px;
  width: 200px;
  object-fit: cover;
`;