import { Avatar, Button, Input, Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { DrawerWrapper, FrameTools, ListStyleWrapper } from './style';
import Pagination from '../../components/Pagination';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import TableContent from '../../components/TableContent';
import ModalCreateStyleGeneral from '../../components/ModalCreateStyleGeneral';
import {
  getListStyle,
  deleteStyle,
  getListStyleSelectors,
  getStyleInhouse,
} from '../../services/listStyleService';
import { ToastError, ToastSuccess } from '../../components/toast-message/ToastMessage';
import IconSearch from '../../assets/images/icon-search.svg';
import IconFilter from '../../assets/images/icon-filter.svg';
import IconEditStyle from '../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../assets/images/icon-delete-style.svg';
import IconSelectFilter from '../../assets/images/icon-select-filter.svg';
import IconCloseFilter from '../../assets/images/icon-close-filter.svg';
import { toastMessage } from '../../config/constants';
import {
  FieldConfigStyle,
  KEY_CONFIG,
  PROJECT_DEFAULT,
  StyleType,
  baseModels,
  styleTypes,
} from '../../constants';
import { UploadOutlined } from '@ant-design/icons/lib';
import { checkIsImage, convertConfig } from '../../utils/helper';

import IcDownBlack from '../../assets/icons/ic_down_back.svg';

// import jsYaml from 'js-yaml';
const jsYaml = require('js-yaml');
const ListStyle = () => {
  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 70,
    },
    {
      title: 'AI STYLE NAME',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: 'POSITIVE PROMPT',
      dataIndex: 'config',
      /* dataIndex: 'positivePrompt', */
      key: 'positivePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.positivePrompt || ''}</div>;
      },
    },
    {
      title: 'NEGATIVE PROMPT',
      dataIndex: 'config',
      /*  dataIndex: 'negativePrompt', */
      key: 'negativePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.negativePrompt || ''}</div>;
      },
    },
    {
      title: 'CONFIG',
      dataIndex: 'config',
      key: 'config',
      className: 'config-column',
      width: 320,
      render: (value: any, record: any) => {
        return (
          <div>
            <ul>
              {KEY_CONFIG[record.styleType]
                ?.filter((key: string) => key !== 'imageSize')
                ?.map((key: string) => {
                  if (key === 'mode') {
                    if (record.styleType === StyleType.AVATAR) return <></>;
                  }
                  if (key === 'baseModel') {
                    return `${FieldConfigStyle[key]} :  ${value[key] || baseModels[0]?.label}`;
                  }
                  return (
                    <>
                      <li>{`${FieldConfigStyle[key]} : ${
                        value[key] !== undefined ? value[key] : ''
                      }`}</li>
                    </>
                  );
                })}
              {record.styleType === 'avatar' && (
                <li>{`Base Model : ${value?.baseModel || baseModels[0]?.label}`}</li>
              )}
            </ul>
          </div>
        );
      },
    },
    {
      title: 'BASE MODEL',
      dataIndex: 'baseModel',
      key: 'baseModel',
      width: 120,
      render: (value: any, record: any) => {
        console.log('valueeee', value);
        const model = baseModels.find((item) => item.value === value)?.label;
        return <div>{model}</div>;
      },
    },
    {
      title: 'STYLE TYPE',
      dataIndex: 'styleType',
      key: 'styleType',
      width: 120,
    },
    {
      title: 'AI FAMILY',
      dataIndex: 'aiFamily',
      key: 'aiFamily',
      width: 120,
      render: (value: any) => {
        return <div>{value?.displayName || ''}</div>;
      },
    },
    {
      title: 'STYLE',
      dataIndex: 'config',
      key: 'style',
      width: 120,
      render: (value: any) => {
        return <div>{value?.style || ''}</div>;
      },
    },
    /* {
      title: 'PROJECT',
      dataIndex: 'project',
      key: 'project',
      width: 130,
      render: (value: any) => (
        <div>
          {value.map((item: any) => (
            <div className="item-project">{item?.projectName} </div>
          ))}
        </div>
      ),
    }, */
    {
      title: 'THUMBNAIL',
      dataIndex: 'key',
      key: 'thumbnail',
      className: 'thumbnail-column',
      width: 240,
      render: (value: string) => {
        const isImage = checkIsImage(value);
        if (isImage) return <Avatar shape="square" size={204} src={value}></Avatar>;
        return (
          <video width="204px" height="204px" key={value} controls>
            <source src={value} />
          </video>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      className: 'action-column',
      render: (id: string, record: any) => (
        <div className="list-action">
          <img src={IconEditStyle} alt="" onClick={() => handleOpenEditStyle(record)} />
          <img src={IconDeleteStyle} alt="" onClick={() => handleOpenDelete(id)} />
        </div>
      ),
    },
  ];
  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [params, setParams] = useState<any>({
    limit: 15,
    page: 1,
    search: '',
    style: '',
    project: PROJECT_DEFAULT,
  });

  const queryClient = useQueryClient();

  const [openFilter, setOpenFilter] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const [filterStatus, setFilterStatus] = useState(false);

  const [openCreateStyle, setOpenCreateStyle] = useState(false);

  const [idStyleDelete, setIdStyleDelete] = useState('');

  const [styleEdit, setStyleEdit] = useState<any>();

  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(1)

  const [styleFilter, setStyleFilter] = useState<any>(null);

  const [nameSearch, setNameSearch] = useState('');

  let [debounce, setDebounce] = useState<any>();
  useEffect(() => {
    const param = {
      page: 1,
      limit: 15,
      search: '',
      style: '',
      project: PROJECT_DEFAULT,
    };
    const page = searchParams.get('page');
    const limit = searchParams.get('limit');
    const search = searchParams.get('name');
    const style = searchParams.get('style');

    if (page) {
      param.page = Number(page);
    }

    if (limit) {
      param.limit = Number(limit);
    }

    if (search) {
      param.search = search;
      setNameSearch(search);
    }

    if (style) {
      param.style = style;
      setStyleFilter(style);
    }
    setParams(param);
  }, []);
  /*   useEffect(() => {
    if (styleFilter) {
     // setFilterStatus(true);
    }
  },[styleFilter]) */
  useEffect(() => {
    const searchParams: any = {};
    for (let key in params) {
      const value = params[key];
      if (value) {
        searchParams[key] = value;
      }
    }
    setSearchParams(searchParams);
  }, [params]);

  useEffect(() => {
    //Handle in case: the user is on the list style page, then navigate to the list style page itself
    if (!search) {
      setParams({
        page: 1,
        limit: 15,
        search: '',
        style: '',
        project: PROJECT_DEFAULT,
      });
      // setStyleFilter('');
      setNameSearch('');
    }
  }, [search]);

  const { data: listStyle } = useQuery(['get-list-style', params], () => getListStyle(params), {
    onSuccess: (data) => {
      if (data.items.length === 0 && params.page > 1) {
        setParams({ ...params, page: params.page - 1 });
      }
      queryClient.invalidateQueries({
        queryKey: ['get-style-inhouse'],
      });
      setLoading(false);
    },
  });
  const { data: listStyleInhouse } = useQuery(
    'get-style-inhouse',
    () => getStyleInhouse({ limit: 500 }),
    {}
  );
  const mutationDeleteStyle = useMutation((id: string) => deleteStyle(id), {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: ['get-list-style'],
      });
      ToastSuccess('Delete Successfully!');
    },
    onError: () => {
      ToastError(toastMessage.error);
    },
  });

  const handleChangeSearchName = (e: any) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        setParams({ ...params, search: e.target.value, page: 1 });
      }, 500)
    );
    setNameSearch(e.target.value);

    const dataSearch = listStyle?.items.filter((item: any) => {
      return item.name === nameSearch;
    });
    return dataSearch;
  };

  const handleChangeFilterStyle = (style: string) => {
    setStyleFilter(style);
  };

  const showDrawerFilter = () => {
    setOpenFilter(true);
    // setLoading(true);
  };

  const onCloseDrawerFilter = () => {
    setOpenFilter(false);
  };

  const handleAddStyle = () => {
    setOpenCreateStyle(true);
  };

  const handleApplyDrawerFilter = () => {
    //setStyleFilter(style);
    if (styleFilter) {
      setParams({ ...params, styleType: styleFilter, page: 1 });
      const dataFilter = listStyleInhouse?.data?.items.filter((item: any) => {
        return item.config.style === styleFilter;
      });
      setFilteredData(dataFilter || []);
      // setFilterStatus(true);
      setOpenFilter(false);
    } else {
      setFilterStatus(false);
      setOpenFilter(false);
    }
  };

  const hanldeResetDrawerFilter = () => {
    setStyleFilter(null);
    setParams({
      ...params,
      styleType: '',
    });
    setOpenFilter(false);
    //setReset(true);
    //setFilterStatus(true);
    /*     setParams({
      ...params,
      style: '',
    });
    setFilterStatus(false);  */
  };

  const handleOpenDelete = (id: string) => {
    setOpenDelete(true);
    setIdStyleDelete(id);
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
    setIdStyleDelete('');
  };

  const handleDelete = () => {
    setOpenDelete(false);
    mutationDeleteStyle.mutate(idStyleDelete);
  };

  const handleOpenEditStyle = (style: any) => {
    setStyleEdit(style);
    setOpenCreateStyle(true);
  };

  return (
    <ListStyleWrapper>
      <div className="content">
        <div className="title">AI Style</div>
        <div className="list-style">
          <div className="box-search">
            <div className="search-and-filter">
              <Input
                allowClear
                value={nameSearch}
                prefix={<img src={IconSearch} alt="" />}
                className="input-search"
                placeholder="Search style name"
                onChange={handleChangeSearchName}
              />
              <div className="filter" onClick={showDrawerFilter}>
                <img src={IconFilter} alt="" />
                <span>Filter</span>
              </div>
            </div>
            <FrameTools>
              <div className="add-ai" onClick={handleAddStyle}>
                <div className="plus">+</div>
                <div>Add AI Content</div>
              </div>
            </FrameTools>
          </div>
          <TableContent
            columns={columns}
            dataSource={listStyle?.items}
            height="55vh"
            loading={loading}
          />
        </div>
        <Pagination
          setParams={setParams}
          params={params}
          limit={listStyle?.limit || 0}
          total={listStyle?.totalItems}
        />
      </div>
      <DrawerWrapper
        placement="right"
        open={openFilter}
        closable={false}
        width={480}
        onClose={onCloseDrawerFilter}>
        <div>
          <div className="title-filter">
            <span>Advanced Filter</span>
            <img src={IconCloseFilter} alt="" onClick={onCloseDrawerFilter} />
          </div>
          <div className="style">Style type</div>
          <Select
            suffixIcon={<img src={IcDownBlack} />}
            style={{ width: '100%' }}
            options={styleTypes}
            size="large"
            placeholder="none"
            disabled={!!styleEdit}
            value={styleFilter}
            onChange={handleChangeFilterStyle}
          />
        </div>
        <div className="footer">
          <span className="reset" onClick={hanldeResetDrawerFilter}>
            Reset
          </span>
          <div>
            <Button className="btn-cancel" onClick={onCloseDrawerFilter}>
              Cancel
            </Button>
            <Button className="btn-apply" onClick={handleApplyDrawerFilter}>
              Apply
            </Button>
          </div>
        </div>
      </DrawerWrapper>
      {openCreateStyle && (
        <ModalCreateStyleGeneral
          styleEdit={styleEdit}
          setStyleEdit={setStyleEdit}
          open={openCreateStyle}
          setOpen={setOpenCreateStyle}
          params={params}
          setParams={setParams}
          setStyleFilter={setStyleFilter}
          setNameSearch={setNameSearch}
          listStyleInhouse={listStyleInhouse}
        />
      )}
      <ModalConfirmDelete
        open={openDelete}
        handleCancelDelete={handleCancelDelete}
        handleDelete={handleDelete}
        title={'Are you sure to delete style?'}
        tittleDelete={'Delete'}
      />
    </ListStyleWrapper>
  );
};
export default ListStyle;
