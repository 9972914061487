import { useLocation, useNavigate } from 'react-router-dom';
import { CollapseWrapper, FrameHeader, FrameIcRow, FrameSub, FrameSubs, FrameTitle, SidebarWrapper } from './style';
import Logo from '../../assets/icons/ic_logo_cms.svg';
import IconRight from '../../assets/images/icon-right.svg';
import IconDown from '../../assets/images/icon-down.svg';
import IcSetting from '../../assets/icons/ic_setting.svg';
import IcTab1Active from '../../assets/icons/ic_tab_1_active.svg';
import IcTab2Active from '../../assets/icons/ic_tab_2_active.svg';
import IcTab2 from '../../assets/icons/ic_tab2.svg';
import { useEffect, useMemo, useState } from 'react';
import type { CollapseProps, MenuProps } from 'antd';
import { Collapse } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ROLES } from '../../constants';
const Panel = Collapse.Panel;

const TABS = {
  NONE: '',
  TAB1: '1',
  TAB2: '2'
}

// @ts-ignore
const items: any = [
  {
    key: TABS.TAB1,
    icon: IcSetting,
    icActive: IcTab1Active,
    label: 'Config',
    children: [
      {label: 'Account Management', router: '/account', isAdmin: true},
    ],
  },
  {
    key: TABS.TAB2,
    icon: IcTab2,
    icActive: IcTab2Active,
    label: 'AI Art',
    children: [
      {label: 'AI style default', router: '/style', isAdmin: true},
      {label: 'Project ', router: '/projects', isAdmin: false}
    ],
  },
];

const Sidebar = () => {
 
  const location = useLocation();
  const navigate = useNavigate();
  
  const pathname = location.pathname;
  const [showSubMenu, setShowSubMenu] = useState(TABS.TAB2);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const onShowSubMenu = (tab: string) => {
    if ((showSubMenu === TABS.TAB1 && tab ===TABS.TAB1) || (showSubMenu === TABS.TAB2 && tab ===TABS.TAB2)) {
      setShowSubMenu(TABS.NONE);
    } else {
      setShowSubMenu(tab);
    }
  }

  useEffect(() => {
    if ((pathname.includes('projects') || pathname.includes('style'))) setShowSubMenu(TABS.TAB2);
  }, [pathname])

  const renderSub = (sub: any, i: number) => {
    const active = pathname.includes(sub?.router);
    return(
      <FrameSub onClick={() => navigate(sub?.router)} active={active}>
        {sub?.label}
      </FrameSub>
    )
  }

  const renderSide = (item: any, index: number) => {
    const active = showSubMenu === item.key
    return(
      <Panel
        header={
          <FrameHeader onClick={() => onShowSubMenu(item.key)} active={active}>
            <img src={active ? item?.icActive : item?.icon}/>
            <FrameTitle>{item?.label}</FrameTitle>
            <FrameIcRow src={IconRight} alt="" active={active}/>
          </FrameHeader>
        }
        key={item?.key}
      >
        <FrameSubs>
          {item.children.filter((value: any) => {
            return !value?.isAdmin || (value?.isAdmin && userInfo?.role === ROLES.ADMIN)
          }).map((sub: any, i: number) => {
            return renderSub(sub, i)
          })}
        </FrameSubs>
      </Panel>
    )
  }

  const itemOption = useMemo(() => {
    if (userInfo?.role === ROLES.ADMIN)
      return items;
    return [...items].slice(1, 2);
  }, [userInfo?.role])

  return (
    <SidebarWrapper>
      <div className="logo">
        <img src={Logo} alt="" />
        AI CMS
      </div>
      <div className="menu">
        <CollapseWrapper
          defaultActiveKey={[TABS.TAB2]}
          activeKey={showSubMenu}
        >
          {itemOption.map((item: any, index: number) => {
            return renderSide(item, index)
          })}
        </CollapseWrapper>
      </div>
    </SidebarWrapper>
  )
};

export default Sidebar;
