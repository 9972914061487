import React, { useEffect, useState } from 'react';
import { BtnAddSegment, FrameImageSize, TxtTitle, Wrapper } from './style';
import IcAdd from '../../../../assets/icons/ic_add_segment.svg';
import TableContent from '../../../../components/TableContent';
import Pagination from '../../../../components/Pagination';
import {
  FrameAction, FrameEdit,
  FrameProject,
  FrameProjectRow,
  FrameResend,
  TxtRow,
  TxtRowEmail,
} from '../../../UserManagement/style';
import { ROLES, STATUS_VERIFY } from '../../../../constants';
import { capitalizeFirstLetter, checkOverPage } from '../../../../utils/helper';
import { checkPermissionEditAccount, checkResend } from '../../../../utils/roles';
import IcEdit from '../../../../assets/icons/ic_edit.svg';
import IcDelete from '../../../../assets/icons/ic_delete.svg';
import { useMutation } from 'react-query';
import { deleteStyle } from '../../../../services/listStyleService';
import { ToastError, ToastSuccess } from '../../../../components/toast-message/ToastMessage';
import { toastMessage } from '../../../../config/constants';
import { createSegment, deleteSegmentApi, getListSegment, updateSegmentApi } from '../../../../services/segmentService';
import { AddSegment } from '../AddSegment';
import ModalConfirmDelete from '../../../../components/ModalConfirmDelete';
import { IProps } from './interface';
import { useParams } from 'react-router-dom';
import { StringMappingType } from 'typescript';

export const SegmentManage  = (props: IProps) => {
  const { handleReload } = props;
  const { id } = useParams();
  const [segments, setSegment] = useState({
    items: [],
    limit: 10,
    totalItems: 0
  });
  const [openModal, setOpenModal] = useState(false);

  const [params, setParams] = useState<any>({
    limit: 10,
    page: 1,
    name: '',
    projectId: id
  });

  const [segmentEdit, setSegmentEdit] = useState<any>(null);

  const [openDelete, setOpenDelete] = useState(false);

  const columns = [
    {
      title: 'USER SEGMENT',
      dataIndex: 'name',
      key: 'name',
      width:140,
    },
    {
      title: 'VALUE',
      dataIndex: 'firebaseValue',
      key: 'firebaseValue',
      width: 120,
    },
    {
      title: 'IMAGE SIZE',
      dataIndex: 'imageSize',
      key: 'imageSize',
      width: 120,
      render: (text: string, record: any) => {
        return(
          <FrameImageSize>
            <span>AI Art: {record.aiArtImageSize}</span>
            <span>AI Fashion: {record.aiFashionImageSize}</span>
            <span>AI Super Art: {record.aiSuperArtImageSize}</span>
          </FrameImageSize>
        )
      }
    },
    {
      title: 'BASE MODEL',
      dataIndex: 'imageSize',
      key: 'imageSize',
      width: 120,
      render: (text: string, record: any) => {
        return(
          <FrameImageSize>
            <span>AI Art: {record.aiArtBaseModel}</span>
            <span>AI Fashion: {record.aiFashionBaseModel}</span>
            <span>AI Super Art: {record.aiSuperArtBaseModel}</span>
          </FrameImageSize>
        )
      }
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      className: 'action',
      render: (text: string, row: any) => {
        return(
          <FrameAction>
            <FrameEdit onClick={() => editSegment(row)}>
              <img src={IcEdit}/>
            </FrameEdit>
            <FrameEdit onClick={() => showPopupDelete(row)}>
              <img src={IcDelete}/>
            </FrameEdit>
          </FrameAction>);
      },
    },
  ];

  const showPopupDelete = (value: any) => {
    setSegmentEdit(value);
    setOpenDelete(true);
  }

  const editSegment = (data: any) => {
    setSegmentEdit(data);
    setOpenModal(true);
  }

  useEffect(() => {
    muatationListSegment.mutate();
  }, [params])

  const muatationListSegment = useMutation(
    () => getListSegment(params), {
    onSuccess: (res) => {
      if (res?.data) {
        setSegment(res?.data);
        if (openModal) closeModal();
        if (segmentEdit) setSegmentEdit(null);
        if (openDelete) handleCancelDelete();
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  const muatationAddSegment = useMutation(
    (payload: any) => createSegment(payload), {
      onSuccess: (res) => {
        if (res?.data) {
          ToastSuccess('Add User Segment success!');
          muatationListSegment.mutate(params)
          handleReload();
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  const muatationUpdateSegment = useMutation(
    (payload: any) => updateSegmentApi(segmentEdit?._id, payload), {
      onSuccess: (res) => {
        if (res?.data) {
          ToastSuccess('Update User Segment success!')
          muatationListSegment.mutate(params);
          handleReload();
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  const muatationDeleteSegment = useMutation(
    () => deleteSegmentApi(segmentEdit?._id), {
      onSuccess: (res) => {
        if (res?.data) {
          const { page, limit } = params;
          ToastSuccess('Delete User Segment success!')
          const pageChange = checkOverPage(page, limit, segments.totalItems);
          if (page !== pageChange) {
            setParams({...params, page: pageChange})
          } else {
            muatationListSegment.mutate(params);
          }
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    });

  const closeModal = () => {
    setOpenModal(false);
    setSegmentEdit(null);
  }

  const addNewSegment = (payload: any) => {
    muatationAddSegment.mutate({
      ...payload,
      project: id
    })
  }

  const updateSegment = (payload: any) => {
    muatationUpdateSegment.mutate({
      ...payload,
      project: id
    })
  }

  const handleDelete = () => {
    muatationDeleteSegment.mutate()
  }

  const handleCancelDelete = () => {
    setOpenDelete(false);
    setSegmentEdit(null);
  }

  return(
    <Wrapper>
      <TxtTitle>Manage segment</TxtTitle>
      <BtnAddSegment onClick={() => setOpenModal(true)}>
        <img src={IcAdd}/>
        Add User segment
      </BtnAddSegment>
      <TableContent
        columns={columns}
        dataSource={segments?.items}
        height="45vh"
        loading={false}
      />
      <Pagination
        setParams={setParams}
        params={params}
        limit={segments?.limit || 0}
        total={segments?.totalItems || 0}
        showOption={false}
      />
      <AddSegment
        open={openModal} closeModal={closeModal}
        addNewSegment={addNewSegment}
        updateSegment={updateSegment}
        segmentEdit={segmentEdit}
      />
      <ModalConfirmDelete
        open={openDelete}
        handleCancelDelete={handleCancelDelete}
        handleDelete={handleDelete}
        title={'Are you sure you want to delete this segment?'}
        tittleDelete={'Remove'}
      />
    </Wrapper>
  )
}